import { Box, Card, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";

import receipt from "receipt";
import { format } from "date-fns";
import { Helmet } from "react-helmet-async";
import { chain } from "lodash";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { useQueryOneCompanyByCustomerGroupId } from "../../api/Company";
import { useQueryOneOrderTransactionForReceipt } from "../../api/OrderTransaction";
import { pascalCase } from "change-case";

receipt.config.currency = "$";
receipt.config.ruler = "-";

export const PrintReceipt = () => {
  const { uuid } = useParams<string>();
  const { data: transaction } = useQueryOneOrderTransactionForReceipt(uuid);

  const { order, payment_method } = transaction ?? {};
  const { customer } = order ?? {};
  const { data: company } = useQueryOneCompanyByCustomerGroupId(customer?.customer_group_id);

  const output = receipt.create([
    {
      type: "text",
      value: [
        company?.name,
        company?.abn && `ABN: ${company?.abn}`,
        company?.address,
        company?.phone && `Phone: ${company?.phone}`,
        company?.email
      ].filter(Boolean),
      align: "center"
    },
    { type: "empty" },
    {
      type: "text",
      value: "RECEIPT",
      align: "center"
    },
    { type: "empty" },
    {
      type: "text",
      value: [
        customer?.full_name,
        ...chain(customer?.billing_address)
          .split(",")
          .map(v => v.trim())
          .chunk(2)
          .map(values => values.join(", "))
          .value(),

        chain([
          !!customer?.mobile_number && `Mobile: ${customer?.mobile_number}`,
          !!customer?.phone_number && `Phone: ${customer?.phone_number}`
        ])
          .compact()
          .head()
          .value(),
        !!customer?.email && `Email: ${customer?.email}`
      ],
      align: "left"
    },
    { type: "empty" },
    {
      type: "properties",
      lines: [{ name: "Order Number", value: order?.reference_number }]
    },
    { type: "empty" },
    {
      type: "properties",
      lines: [
        { name: "Particular", value: pascalCase(transaction?.transaction_for ?? "") },
        {
          name: "Amount",
          value: toDecimalFromFloat(transaction?.amount)
        },
        { name: "Transaction Number", value: transaction?.reference_number },
        {
          name: "Date",
          value: format(new Date(transaction?.date), "dd/MM/yyyy p")
        },
        {
          name: "Payment Method",
          value: transaction?.payment_method_raw || payment_method?.name
        }
      ]
    }
  ]);

  return (
    <>
      <Helmet title={`Receipt ${transaction?.reference_number}`} />

      <Box display="flex" justifyContent="center">
        <Card sx={{ p: 2 }}>
          <CardContent>
            <pre>{output}</pre>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
