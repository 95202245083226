import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationAddOrderLineItem } from "../../api/OrderLineItem";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import OrderLineItemForm from "./OrderLineItemForm";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const OrderLineItemAdd = () => {
  const mutation = useMutationAddOrderLineItem(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Order Line Item" />

      <Divider my={6} />

      <OrderLineItemForm mutation={mutation} />
    </Paper>
  );
};

export default OrderLineItemAdd;
