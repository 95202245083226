import { useMutationAbstract, useQueryOneModel } from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";

export const QUERY_KEY = "order-fitting-runs-export";

export const useQueryOneOrderFittingRunsExport = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid, {
    retry: 15,
    retryDelay: 1000
  });

export const useOrderFittingRunsExport = () => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: input => axios.post(`/api/${QUERY_KEY}`, input).then(({ data }) => data)
  });
};
