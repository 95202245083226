import {
  useMutationAbstract,
  useMutationAddModelV2,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import { UseQueryOptions } from "@tanstack/react-query/src/types";
import { AxiosRequestConfig } from "axios";
import { Query, useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import { QUERY_KEY as ORDER_QUERY_KEY } from "./Order";
import { useEventHandledPromise } from "../hooks/useEventHandledPromise.ts";
import { useChannel } from "@harelpls/use-pusher";

export const QUERY_KEY = "order-line-item";

export const useQueryOrderLineItems = () => useQueryModels(QUERY_KEY);

export function useQueryOrderLineItemsByOrderId<T = any, TError = unknown>(
  order_id?: number,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
) {
  const query = {
    filter: {
      order_id
    }
  };

  return useQuery<T, TError>({
    enabled: !!order_id,
    queryKey: [QUERY_KEY, "list", "by-order-id", order_id],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/by-order-id`, {
          signal,
          params: query,
          ...requestConfig
        })
        .then(({ data }) => data),
    ...options
  });
}

export function useQueryOrderLineItemsDerivedInfo<T = any, TError = unknown>(
  order_id?: number,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
) {
  const query = {
    filter: {
      order_id
    }
  };

  return useQuery<T, TError>({
    enabled: !!order_id,
    queryKey: [QUERY_KEY, "derived-info", "by-order-id", order_id],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/derived-info`, {
          signal,
          params: query,
          ...requestConfig
        })
        .then(({ data }) => data),
    ...options
  });
}

export const useQueryOrderLineItemFormOptions = () =>
  useQueryFormOptionsModel<{
    products: any;
  }>(QUERY_KEY, {
    suspense: false
  });

export const useQueryOneOrderLineItem = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid, {
    suspense: false
  });

export const useMutationCopyOrderLineItem = () => {
  interface Response {
    copied_uuid: string;
  }

  const channel = useChannel("OrderLineItemCopied");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response, unknown, string>({
    mutationKey: [QUERY_KEY, "copy"],
    mutationFn: uuid =>
      axios
        .post<Response>(`/api/${QUERY_KEY}/${uuid}/copy`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, ORDER_QUERY_KEY].includes(queryKey as string);
      }
    }
  });
};

export const useMutationAddOrderLineItem = (uuid: string) =>
  useMutationAddModelV2(QUERY_KEY, uuid, {
    pusherChannelName: "OrderLineItemCreated",
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, ORDER_QUERY_KEY].includes(queryKey as string);
      }
    }
  });

export const useMutationEditOrderLineItem = (uuid: string) =>
  useMutationEditModel(QUERY_KEY, uuid, {
    pusherChannelName: "OrderLineItemUpdated",
    invalidateQueryKey: {
      predicate: (query: Query) => {
        const [queryKey] = query.queryKey;

        return [QUERY_KEY, ORDER_QUERY_KEY].includes(queryKey as string);
      }
    }
  });

export const useMutationDeleteOrderLineItem = () =>
  useMutationDeleteModel(QUERY_KEY, { pusherChannelName: "OrderLineItemDeleted" });

export const useMutationBulkDeleteOrderLineItem = () => useMutationBulkDeleteModelV2(QUERY_KEY);
