import axios from "../utils/axios";
import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useMutationRestoreDeletedModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import sleep from "../utils/sleep";

export const useQueryUsers = () => useQueryModels("users");

export const useQueryOneUser = (uuid?: string) => useQueryOneModel<any>("users", uuid);

export const useQueryUserFormOptions = () => useQueryFormOptionsModel<{ roles: string[] }>("users");

export const useMutationAddUser = (uuid: string) => useMutationAddModel("users", uuid);

export const useMutationEditUser = (uuid: string) => useMutationEditModel("users", uuid);

export const useMutationRestoreDeletedUser = () => useMutationRestoreDeletedModel("users");

export const useMutationDeleteUser = () => useMutationDeleteModel("users");

export const useMutationBulkDeleteUser = () => useMutationBulkDeleteModelV2("users");

export const useMutationInviteUser = (uuid: string) =>
  useMutationAbstract({
    mutationKey: ["users"],
    mutationFn: data =>
      axios
        .post("/api/users/invite", {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(async () => {
          await sleep(1500);
        })
  });

export const useMutationUserSetPassword = () => {
  const { uuid } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationAbstract<{
    password: string;
    confirm_password: string;
  }>({
    mutationFn: data =>
      axios.post(`/api/users/set-password/${uuid}`, data).then(({ data }) => data),
    onSuccess: () => {
      enqueueSnackbar("Success", {
        variant: "success",
        preventDuplicate: true
      });
    }
  });
};
