import { useMutationSaveOrderFitting } from "../../../api/OrderFitting.ts";
import { useState } from "react";
import { TextFieldInput } from "./TextFieldInput.tsx";
import { v4 } from "uuid";

export const ErrorReasonDetailInput = ({
  uuid: _uuid,
  order_uuid,
  error_detail
}: {
  order_uuid: string;
  uuid?: string;
  error_detail?: string;
}) => {
  const [value, setValue] = useState<string | undefined>(error_detail);

  const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

  const uuid = _uuid ?? v4();

  const handleOnChange = async (value?: string) => {
    setValue(value);
    const prev = value;
    saveOrderFitting({
      uuid,
      order_uuid,
      error_detail: value?.trim() || null
    }).catch(() => setTimeout(() => setValue(prev), 500));
  };

  return (
    <TextFieldInput
      type={"text"}
      isLoading={isLoading}
      onChange={handleOnChange}
      defaultValue={value}
      fullWidth
      multiline
      minRows={2}
      maxRows={15}
    />
  );
};
