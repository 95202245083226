import { MenuItem, Select } from "@mui/material";
import useHealthChecker from "../../../hooks/useHealthChecker.ts";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { memo } from "react";

export const SelectInput = memo(
  ({
    title,
    value,
    onChange,
    isLoading,
    formOptions,
    disabled
  }: {
    title: string;
    value?: string | null;
    onChange?: SelectInputProps<string>["onChange"];
    isLoading?: boolean;
    disabled?: boolean;
    formOptions?: any[]; // [uuid, name]
  }) => {
    const { isSuspendMutations } = useHealthChecker();

    return (
      <Select
        disabled={disabled || isSuspendMutations || isLoading}
        variant="standard"
        size="small"
        value={value ?? ""}
        onChange={onChange}
        title={title}
        fullWidth
        defaultOpen
        autoFocus
        sx={{ minWidth: 100 }}
      >
        <MenuItem value="">
          <em style={{ color: "grey" }}>None</em>
        </MenuItem>

        {formOptions?.map(([uuid, name]) => (
          <MenuItem key={uuid} value={uuid}>
            {name}
          </MenuItem>
        ))}
      </Select>
    );
  }
);
