import { reduce } from "lodash";
import { OrderStatus } from "../types/order_status";

const calculateOrderStatusName = (givenStatus: number) =>
  reduce(
    OrderStatus,
    function (status: null | OrderStatus, statusName, statusBitValue) {
      if (Number(statusBitValue)) {
        if (Number(statusBitValue) === (givenStatus & Number(statusBitValue))) {
          return statusName;
        }

        return status;
      }

      return status;
    },
    null
  );

export default calculateOrderStatusName;
