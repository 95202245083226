import React, { useMemo } from "react";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import { Card, Checkbox, Chip, InputBase, Link } from "@mui/material";
import { debounce, defaultTo, get, isEmpty } from "lodash";
import {
  useMutationOrderSetIsOnHold,
  useMutationOrderSetNotesOutstanding,
  useQueryOrderFormOptionsForOutstanding
} from "../../api/Order";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { endOfDay, format, isValid, parseISO, startOfYear } from "date-fns";
import { getGridOperatorsOnlyBy } from "../../utils/getGridStringOperatorsOnlyBy.ts";
import { DateRangeOperator } from "../../components/data-grid-v2/DateRangeOperator.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const OutstandingOrdersList = () => {
  const { mutateAsync: setIsOnHold } = useMutationOrderSetIsOnHold();
  const { mutateAsync: setNotesOutstanding } = useMutationOrderSetNotesOutstanding();
  const { data: formOptions } = useQueryOrderFormOptionsForOutstanding();

  useSetPageTitle("Outstanding Orders");

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "reference_number",
        headerName: "Order #",
        valueGetter: (value, row) => row.reference_number,
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"]),
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="hover"
              to={generatePath("/order/:uuid/finalized-quote", {
                uuid: row.uuid
              })}
            >
              {value}

              {!!row?.is_test_mode && (
                <Chip label="Test" size="small" color="warning" sx={{ ml: 1 }} />
              )}
            </Link>
          );
        }
      },
      {
        field: "quote_accepted_at",
        headerName: "Accepted Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.quote_accepted_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "customer.customer_group.id",
        headerName: "Customer Group",
        width: 150,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row.customer?.customer_group?.name,
        type: "singleSelect",
        valueOptions: formOptions?.customer_groups?.map((customerGroup: any) => {
          return {
            value: customerGroup.id,
            label: customerGroup.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "order_type",
        headerName: "Order Type",
        width: 180,
        valueGetter: (value, row, column) => get(row, column.field),
        type: "singleSelect",
        valueOptions: formOptions?.order_types,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "is_invoiced_at",
        headerName: "Invoiced Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.is_invoiced_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 180,
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        },
        type: "singleSelect",
        valueOptions: formOptions?.customers?.map((customer: any) => {
          return {
            value: customer.id,
            label: customer.full_name_with_company
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "customer.email",
        headerName: "Customer Email",
        width: 180,
        renderCell: params => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", {
                uuid: row.customer?.uuid
              })}
            >
              {value}
            </Link>
          );
        },
        // for sorting
        valueGetter: (value, row, column) => get(row, column.field)
      },
      {
        field: "sales_rep_id",
        headerName: "Sales Rep",
        width: 180,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row?.sales_rep?.full_name,
        type: "singleSelect",
        valueOptions: formOptions?.sales_rep_users?.map((user: any) => ({
          value: user.id,
          label: user.full_name
        })),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sold_price",
        headerName: "Order Total",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "transactions_sum",
        headerName: "Receipts",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "outstanding_amount",
        headerName: "Outstanding",
        width: 120,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "notes_outstanding",
        headerName: "Notes",
        width: 300,
        renderCell: ({ row }: GridRenderCellParams) => (
          <InputBase
            defaultValue={defaultTo(row?.notes_outstanding, "")}
            size="small"
            fullWidth
            multiline
            rows={2}
            onKeyDown={e => {
              e.stopPropagation();
            }}
            onChange={debounce(e => {
              setNotesOutstanding({
                uuid: row.uuid,
                notes_outstanding: isEmpty(e.target.value) ? null : e.target.value
              });
            }, 800)}
          />
        )
      },
      {
        field: "is_on_hold_at",
        headerName: "On Hold",
        width: 100,
        filterable: false,
        renderCell: ({ row, api }: GridRenderCellParams) => (
          <Checkbox
            checked={!!row?.is_on_hold_at}
            onChange={(e, checked) => {
              api.updateRows([{ ...row, is_on_hold_at: checked ? new Date() : null }]);
              api.setLoading(true);
              setIsOnHold({
                uuid: row.uuid,
                is_on_hold_at: row?.is_on_hold_at ? null : new Date()
              });
            }}
          />
        )
      }
    ],
    [
      formOptions?.customer_groups,
      formOptions?.customers,
      formOptions?.order_types,
      formOptions?.sales_rep_users,
      setIsOnHold,
      setNotesOutstanding
    ]
  );

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order/outstanding"
        autosizeOnMount={false}
        columns={columns}
        checkboxSelection={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              "customer.customer_group.id": false,
              order_type: false
            }
          },
          filter: {
            filterModel: {
              items: [
                {
                  field: "quote_accepted_at",
                  value: [
                    startOfYear(new Date()).toISOString(),
                    endOfDay(new Date()).toISOString()
                  ],
                  id: "accepted_date_range",
                  operator: "date_between"
                }
              ]
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        slots={{
          toolbar: DataGridToolbar
        }}
      />
    </Card>
  );
};

export default OutstandingOrdersList;
