import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import OrderLineItemForm from "./OrderLineItemForm";
import { useMutationEditOrderLineItem } from "../../api/OrderLineItem";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const OrderLineItemEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditOrderLineItem(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit" />

      <Divider my={6} />

      <OrderLineItemForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default OrderLineItemEdit;
