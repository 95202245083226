import { DateInput } from "./DateInput.tsx";
import { useMutationSaveOrderFitting } from "../../../api/OrderFitting.ts";
import { debounce } from "lodash";
import { memo, useState } from "react";
import { v4 } from "uuid";

export const PowdercoatEtaAtInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    powdercoat_eta_at
  }: {
    order_uuid: string;
    uuid?: string;
    powdercoat_eta_at?: string;
  }) => {
    const [value, setValue] = useState<Date | null>(
      powdercoat_eta_at ? new Date(powdercoat_eta_at) : null
    );
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = debounce(async (_value: Date | null) => {
      setValue(_value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        powdercoat_eta_at: _value
      }).catch(() => setTimeout(() => setValue(prev), 500));
    }, 100);

    return <DateInput title="ETA" isLoading={isLoading} onChange={handleOnChange} value={value} />;
  }
);
