import React, { Suspense, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import GlobalStyle from "../components/GlobalStyle.tsx";
import styled from "@emotion/styled";
import PageLoading from "../components/PageLoading.tsx";
import ErrorFallbackMessage from "../components/ErrorFallbackMessage.tsx";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { useBoolean } from "usehooks-ts";
import { SidebarV2 } from "../components/sidebar/SidebarV2";
import { NavbarV2 } from "../components/navbar/NavbarV2.tsx";

export const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: prop => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(5),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  maxWidth: "100%",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    maxWidth: `calc(100% - 240px)`
  })
}));

export function DashboardV2({ children }: { children?: React.ReactNode }) {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const {
    value: openSidebar,
    toggle: handleSidebarToggle,
    setValue: setOpenSidebar
  } = useBoolean(isLgUp);

  useEffect(() => {
    setOpenSidebar(isLgUp);
  }, [isLgUp]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <GlobalStyle />

      <SidebarV2 openSidebar={openSidebar} />

      <Main open={openSidebar}>
        <NavbarV2 openSidebar={openSidebar} handleSidebarToggle={handleSidebarToggle} />

        {/* Had to do it this way with <Suspense> element as parent for QueryErrorResetBoundary due to some weird error: "A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition."*/}
        <Suspense fallback={<PageLoading />}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                key={location.pathname + "-dashboard"}
                onReset={reset}
                FallbackComponent={ErrorFallbackMessage}
              >
                {children}
                <Outlet />
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </Suspense>
      </Main>
    </Box>
  );
}
