import OrderFittingList from "./OrderFittingList.tsx";
import BookedList from "./BookedList.tsx";

const routes = [
  {
    path: "/order-fitting",
    element: <OrderFittingList />,
    children: [
      {
        path: "",
        element: <BookedList />
      }
    ]
  }
];

export default routes;
