import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {
  Card,
  CardContent,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import {
  AppRegistration as UpdatedIcon,
  DashboardCustomize as CreatedIcon
} from "@mui/icons-material";
import async from "../../components/Async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useQuery } from "@tanstack/react-query";

const Code = async(() => import("../../components/Code"));

const Divider = styled(MuiDivider)(spacing);

const CustomTimelineItem = styled(TimelineItem)`
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1200
  }
});

const EventLogsCard = ({ stream, aggregate_id }: { stream: string; aggregate_id?: string }) => {
  const { data } = useQuery<any>({
    enabled: !!aggregate_id,
    queryKey: [
      "evtstore/events",
      "list",
      {
        page: 1,
        limit: 100,
        sort: "!position",
        stream,
        aggregate_id
      }
    ],
    staleTime: 10000
  });

  return (
    <Card
      sx={{
        maxHeight: 450,
        overflowY: "auto"
      }}
    >
      <CardContent>
        <Typography variant="h5">Event Logs</Typography>

        <Divider my={5} />

        <Timeline>
          {data?.rows?.map((eventRaw: any) => {
            const { event } = eventRaw;
            return (
              <CustomTimelineItem key={eventRaw.position}>
                <TimelineOppositeContent color="textSecondary">
                  {`${format(new Date(eventRaw.timestamp), "dd/MM/yyyy pp")}
                  ${formatDistanceToNow(new Date(eventRaw.timestamp), {
                    addSuffix: true
                  })}
                  `}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>
                    {event?.type?.endsWith("Created") ? (
                      <CreatedIcon />
                    ) : event?.type?.endsWith("Updated") ? (
                      <UpdatedIcon />
                    ) : null}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <CustomWidthTooltip
                  title={
                    <>
                      {!!eventRaw?.request_id && (
                        <Typography>Request ID: {eventRaw?.request_id}</Typography>
                      )}
                      <Code>{JSON.stringify(event, null, "\t")}</Code>
                    </>
                  }
                >
                  <TimelineContent>
                    {event?.type}
                    {!!eventRaw?.authorUser && <> by {eventRaw?.authorUser?.email}</>}
                  </TimelineContent>
                </CustomWidthTooltip>
              </CustomTimelineItem>
            );
          })}

          {data?.count > data?.rows?.length && (
            <CustomTimelineItem>
              <TimelineOppositeContent color="textSecondary">
                There are more {data?.count} events
              </TimelineOppositeContent>
            </CustomTimelineItem>
          )}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default EventLogsCard;
