import UserList from "./UserList";
import UserInvite from "./UserInvite";
import UserEdit from "./UserEdit";
import { ThrowUnlessCan } from "../../ThrowUnlessCan";

const routes = [
  {
    path: "/users",
    element: (
      <ThrowUnlessCan I="ReadList" a="users">
        <UserList />
      </ThrowUnlessCan>
    )
  },
  {
    path: "/users/invite",
    element: (
      <ThrowUnlessCan I="InviteUser" a="users">
        <UserInvite />
      </ThrowUnlessCan>
    )
  },
  {
    path: "/users/:uuid/edit",
    element: (
      <ThrowUnlessCan I="UpdateUser" a="users">
        <UserEdit />
      </ThrowUnlessCan>
    )
  }
];

export default routes;
