import React, { Suspense } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider, EmotionCache } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enAU from "date-fns/locale/en-AU";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import { SnackbarProvider } from "notistack";

import { QueryClient, QueryErrorResetBoundary } from "@tanstack/react-query";
import { HealthCheckerProvider } from "./contexts/HealthCheckerContext";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import queryString from "query-string";
import { AxiosError } from "axios";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackMessage from "./components/ErrorFallbackMessage";
import PageLoading from "./components/PageLoading";
import { ReactConfirmMountPoint } from "./components/ReactConfirmMountPoint";
import "./casl";
import { AbilityContext, caslAbility } from "./casl";
import { useHydrateAtoms } from "jotai/utils";
import { queryClientAtom } from "jotai-tanstack-query";
import { Provider as JotaiProvider } from "jotai/react";
import { LicenseInfo } from "@mui/x-license";
import { muiLicenseKey } from "./config.ts";
import axios from "./utils/axios.ts";
import qs from "qs";
import invariant from "tiny-invariant";
import { PageTitleProvider } from "./contexts/PageTitleContext.tsx";
import { PusherProvider } from "./contexts/PusherContext.tsx";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createIDBPersister } from "./components/createIDBPersister.ts";
import { IsRestoringQueryCache } from "./components/IsRestoringQueryCache.tsx";

LicenseInfo.setLicenseKey(muiLicenseKey);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey, signal, pageParam = null }) => {
        const [endpoint, , queryParams] = queryKey ?? [];

        invariant(!!endpoint, "Should not be empty");

        const params = Object.assign({}, !!pageParam && { after: pageParam }, queryParams ?? {});

        const res = await axios.get(
          `/api/${queryKey[0]}` +
            qs.stringify(params, {
              addQueryPrefix: true
            }),
          { signal }
        );
        return res.data;
      },
      retry: (failureCount, error) => {
        if (failureCount >= 4) {
          return false;
        }

        const responseStatus = (error as AxiosError)?.response?.status ?? 500;
        const unauthorizedErrorCode = 401;
        return ![unauthorizedErrorCode].includes(responseStatus);
      },
      refetchOnWindowFocus: false,
      useErrorBoundary: error => {
        const responseStatus = (error as AxiosError)?.response?.status ?? 500;
        const validationErrorCode = 422;
        return ![validationErrorCode].includes(responseStatus);
      },
      networkMode: "always",
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 10000,
      suspense: true
    },
    mutations: {
      networkMode: "always"
    }
  }
});

const persister = createIDBPersister();

const HydrateAtoms = ({ children }: { children: React.ReactNode }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

const clientSideEmotionCache = createEmotionCache();

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(d => ({
    default: d.ReactQueryDevtools
  }))
);

function App({ emotionCache = clientSideEmotionCache }: { emotionCache?: EmotionCache }) {
  const content = useRoutes(routes);
  const location = useLocation();

  const { theme } = useTheme();

  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error quick
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Aldrin ERP" defaultTitle="Aldrin ERP" />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <SnackbarProvider maxSnack={6}>
              <Suspense fallback={<PageLoading />}>
                <QueryErrorResetBoundary>
                  {({ reset }) => (
                    <PersistQueryClientProvider
                      client={queryClient}
                      persistOptions={{
                        persister
                      }}
                    >
                      <AuthProvider>
                        <PusherProvider>
                          <ErrorBoundary
                            key={location.pathname}
                            onReset={reset}
                            FallbackComponent={ErrorFallbackMessage}
                          >
                            <PageTitleProvider>
                              <HealthCheckerProvider>
                                <QueryParamProvider
                                  adapter={ReactRouter6Adapter}
                                  options={{
                                    searchStringToObject: queryString.parse,
                                    objectToSearchString: queryString.stringify
                                  }}
                                >
                                  <AbilityContext.Provider value={caslAbility}>
                                    <ReactConfirmMountPoint />

                                    <JotaiProvider>
                                      <HydrateAtoms>
                                        <IsRestoringQueryCache>{content}</IsRestoringQueryCache>
                                      </HydrateAtoms>
                                    </JotaiProvider>
                                  </AbilityContext.Provider>

                                  {showDevtools && (
                                    <React.Suspense fallback={null}>
                                      <ReactQueryDevtoolsProduction />
                                    </React.Suspense>
                                  )}
                                </QueryParamProvider>
                              </HealthCheckerProvider>
                            </PageTitleProvider>
                          </ErrorBoundary>
                        </PusherProvider>
                      </AuthProvider>
                    </PersistQueryClientProvider>
                  )}
                </QueryErrorResetBoundary>
              </Suspense>
            </SnackbarProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
