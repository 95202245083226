import { Button, Fade, Menu, MenuItem, TableCell } from "@mui/material";
import { Column, Header, flexRender } from "@tanstack/react-table";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useRef } from "react";
import { MoreVertical } from "react-feather";
import { useHover } from "usehooks-ts";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const ColumnHeaderCell = ({ header }: { header: Header<any, unknown> }) => {
  const hoverRef = useRef(null);
  // const isHover = useHover(hoverRef);

  return (
    <TableCell
      ref={hoverRef}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: header.getSize()
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}

      {/*<PopupState variant="popover" popupId="demo-popup-menu">*/}
      {/*  {popupState => (*/}
      {/*    <>*/}
      {/*      <Fade in={isHover}>*/}
      {/*        <MoreVertIcon*/}
      {/*          fontSize="small"*/}
      {/*          sx={{ color: "gray", cursor: "pointer" }}*/}
      {/*          {...bindTrigger(popupState)}*/}
      {/*        />*/}
      {/*      </Fade>*/}
      {/*      <Menu {...bindMenu(popupState)}>*/}
      {/*        <MenuItem>Delete</MenuItem>*/}
      {/*      </Menu>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</PopupState>*/}
      {/*{header.column.getCanFilter() ? (*/}
      {/*  <div>*/}
      {/*    <Filter column={header.column} />*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </TableCell>
  );
};
