import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import AccountGroupForm from "./AccountGroupForm";
import { useMutationEditAccountGroup } from "../../api/AccountGroup";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const AccountGroupEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditAccountGroup(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Payment Method" />

      <Divider my={6} />

      <AccountGroupForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default AccountGroupEdit;
