import { evaluate } from "cel-js";

const colorChoices = {
  hotpink: 'job_type === "notes"',
  green:
    'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && confirmation_status == "confirmed" && job_status == null',
  yellow:
    'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && confirmation_status == "unconfirmed" && job_status == null',
  red: 'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && job_status == "rectification"',
  orange:
    'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && job_status == "to_be_rebooked"',
  pink: 'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && job_status == "back_to_rep"',
  blue: 'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && job_status == "complete"',
  yellowgreen:
    'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && confirmation_status == "left_message" && job_status == null',
  brown:
    'job_type in ["check_measure", "install", "extra_install", "pre_fit", "rectification", "service", "warranty", "pickup", "delivery", "inspection", "notes", "freight", "supply_only"] && confirmation_status == "pending"'
};

export const getOrderFittingBackgroundColor = (orderFitting: any) => {
  const context = {
    job_type: orderFitting.job_type ?? null,
    confirmation_status: orderFitting.install_confirmation_status ?? null,
    job_status: orderFitting.job_status ?? null
  };

  const colorInfo = Array.from(Object.entries(colorChoices)).find(([, expr]) => {
    return evaluate(expr, context);
  });

  return colorInfo?.shift();
};
