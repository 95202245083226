import { ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import {
  useMutationDeleteOrderFitting,
  useMutationSaveOrderFitting,
  useQueryOrderFittingFormOptions
} from "../../../api/OrderFitting.ts";
import { v4 } from "uuid";
import { pick } from "lodash";
import sleep from "../../../utils/sleep.ts";
import { memo } from "react";
import { MoreVertical } from "react-feather";

export const SelectActionsCell = memo(
  ({ row, jobTypesCount }: { row: any; jobTypesCount: any }) => {
    const {
      uuid,
      order: { uuid: order_uuid }
    } = row;

    const jobTypesByOrder = jobTypesCount?.[order_uuid] ?? {};

    const { data: formOptions } = useQueryOrderFittingFormOptions();
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();
    const { mutate: deleteOrderFitting, isLoading: isDeleting } = useMutationDeleteOrderFitting();

    const handleClick = async (job_type: string) => {
      await saveOrderFitting({
        ...pick(row, [
          "fitter_user_uuid",
          "install_time_allowed_in_hrs",
          "installation_notes",
          "job_booked_at",
          "job_booked_sequence",
          "job_booked_time_slot",
          "ta_count"
        ]),
        uuid: v4(),
        order_uuid,
        job_type,
        install_confirmation_status: "unconfirmed"
      });

      await sleep(500);
    };

    const handleDelete = () => deleteOrderFitting(uuid);

    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <>
            <MoreVertical
              fontSize="small"
              style={{ color: "gray", cursor: "pointer" }}
              {...bindTrigger(popupState)}
            />
            <Menu {...bindMenu(popupState)}>
              {formOptions?.job_types?.map(job_type => {
                const jobTypeCount = jobTypesByOrder?.[job_type];
                return (
                  <MenuItem
                    key={job_type}
                    onClick={() => {
                      popupState.close();
                      handleClick(job_type);
                    }}
                    disabled={
                      isLoading ||
                      (![
                        "check_measure",
                        "pickup",
                        "delivery",
                        "extra_install",
                        "rectification",
                        "notes",
                        "inspection"
                      ].includes(job_type) &&
                        jobTypeCount > 0)
                    }
                  >
                    <ListItemText>{`Create ${job_type}`}</ListItemText>

                    {!!jobTypeCount && (
                      <Typography variant="body2" color="text.secondary">
                        ({jobTypeCount})
                      </Typography>
                    )}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={() => {
                  handleDelete();
                }}
                disabled={isDeleting}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    );
  },
  (oldProp, newProp) => {
    return (
      oldProp.row.uuid === newProp.row.uuid && oldProp.row.order.uuid === newProp.row.order.uuid
    );
  }
);
