import { Button, Card } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { v4 } from "uuid";
import { useOrderFittingRunsExport } from "../../api/OrderFittingRunsExport.ts";
import { exportedRunsDialog } from "./components/ExportedRunsDialog.tsx";
import { paramsAtom, queryTypeFilterAtom } from "../../api/OrderFitting.ts";
import { useAtomValue } from "jotai";
import confirm from "../../components/Confirm.tsx";
import { capitalCase } from "change-case";

const exportTypes = ["runs", "schedules"];
const queryTypeToExportTypes = {
  hold: ["schedules"],
  runs: ["runs", "schedules"],
  pending: [] as string[],
  booked: [] as string[]
};

export const Export = ({ sorting }: { sorting?: string[] }) => {
  const { mutateAsync: exportTable, isLoading } = useOrderFittingRunsExport();
  const queryType = useAtomValue(queryTypeFilterAtom);
  const params = useAtomValue(paramsAtom);

  return (
    <Card sx={{ display: "flex", justifyContent: "space-between", padding: 3 }}>
      {exportTypes
        .filter(export_type => queryTypeToExportTypes[queryType]?.includes(export_type))
        .map(export_type => (
          <Button
            key={export_type}
            onClick={async () => {
              if (
                await confirm({
                  confirmation: `You are about to export ${export_type}. Are you sure?`,
                  options: {
                    title: "Confirm"
                  }
                })
              ) {
                const uuid = v4();

                await exportTable({
                  ...params,
                  export_type,
                  uuid,
                  sorting
                });

                await exportedRunsDialog({
                  title: `Exported ${capitalCase(export_type)}`,
                  export_uuid: uuid
                });
              }
            }}
            variant="outlined"
            disabled={isLoading}
            sx={{ m: 1 }}
            startIcon={<FileDownloadIcon />}
          >
            {capitalCase(export_type)}
          </Button>
        ))}
    </Card>
  );
};
