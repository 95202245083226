import React, { useCallback, useMemo, useRef, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DataGridStateful, DataGridStatefulProps } from "./DataGridStateful.tsx";
import { assign } from "lodash";
import { GridFilterModel, GridLogicOperator, GridSortModel } from "@mui/x-data-grid-pro";
import { useDataGridFilterModelQueryParams } from "../../hooks/useDataGridFilterModelQueryParams.ts";

export const DataGridServerIndexBasedPaginated = ({
  apiEndpoint,
  additionalQueryParams,
  ...dataGridProps
}: Omit<DataGridStatefulProps, "rows"> & {
  apiEndpoint: string;
  additionalQueryParams?: Record<string, string | undefined | null>;
}) => {
  const queryClient = useQueryClient();

  const [enableQuery, setEnableQuery] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const filterQueryParams = useDataGridFilterModelQueryParams(filterModel);

  const queryParams = useMemo(() => {
    return assign(
      {
        limit: paginationModel?.pageSize || 25,
        page: (paginationModel?.page || 0) + 1,
        sort: sortModel?.map(sort => `${sort.sort === "desc" ? "!" : ""}${sort.field}`)?.join("|")
      },
      filterQueryParams,
      additionalQueryParams
    );
  }, [
    paginationModel?.pageSize,
    paginationModel?.page,
    sortModel,
    filterQueryParams,
    additionalQueryParams
  ]);

  const { data, isFetching } = useQuery<any>({
    enabled: enableQuery,
    queryKey: [apiEndpoint, "list", queryParams],
    suspense: false,
    staleTime: 10000
  });

  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const rowCountRef = useRef(data?.count || 0);

  const rowCount = useMemo(() => {
    if (data?.count !== undefined) {
      rowCountRef.current = data?.count;
    }
    return rowCountRef.current;
  }, [data?.count]);

  const onPaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setPaginationModel(newPaginationModel);
    },
    [setPaginationModel]
  );

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
    },
    [setSortModel]
  );

  const onFilterModelChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel]
  );

  const onRowsScrollEnd = useCallback(() => {
    queryClient.prefetchQuery({
      queryKey: [apiEndpoint, "list", { ...queryParams, page: queryParams.page + 1 }],
      staleTime: 10000
    });
  }, [queryClient, queryParams, apiEndpoint]);

  return (
    <DataGridStateful
      setEnableQuery={setEnableQuery}
      // logLevel="debug"
      autoPageSize={false}
      rows={data?.rows ?? []}
      rowCount={rowCount}
      filterDebounceMs={800}
      sortingMode="server"
      filterMode="server"
      paginationMode="server"
      disableColumnPinning
      disableColumnReorder
      loading={isFetching}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      onRowsScrollEnd={onRowsScrollEnd}
      scrollEndThreshold={550}
      getRowHeight={() => "auto"}
      slotProps={{
        cell: {
          style: { paddingTop: "10px", paddingBottom: "10px" }
        },
        filterPanel: {
          logicOperators: [GridLogicOperator.And]
        },
        ...dataGridProps.slotProps
      }}
      {...dataGridProps}
    />
  );
};
