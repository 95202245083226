import { useMutationSaveOrderFitting } from "../../../api/OrderFitting.ts";
import { memo, useState } from "react";
import { TextFieldInput } from "./TextFieldInput.tsx";
import { v4 } from "uuid";

export const JobBookedSequenceInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    job_booked_sequence
  }: {
    order_uuid: string;
    uuid?: string;
    job_booked_sequence?: string;
  }) => {
    const [value, setValue] = useState(job_booked_sequence);

    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = async (value?: string) => {
      setValue(value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        job_booked_sequence: value ? Number(value) : null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    };

    return (
      <TextFieldInput
        type={"number"}
        isLoading={isLoading}
        onChange={handleOnChange}
        defaultValue={value}
      />
    );
  }
);
