import { memo, useState } from "react";
import { TextFieldInput } from "./TextFieldInput.tsx";
import { useMutationSaveOrderFitting } from "../../../api/OrderFitting.ts";
import { v4 } from "uuid";
// trigger gha 2

export const NotesReferenceInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    notes_reference
  }: {
    order_uuid: string;
    uuid?: string;
    notes_reference?: string;
  }) => {
    const [value, setValue] = useState<string | undefined>(notes_reference);

    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = async (value?: string) => {
      setValue(value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        notes_reference: value?.trim() || null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    };

    return (
      <TextFieldInput
        type={"text"}
        isLoading={isLoading}
        onChange={handleOnChange}
        defaultValue={value}
        fullWidth
        multiline
        minRows={2}
        maxRows={15}
      />
    );
  }
);
