import React from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Chip, Link, Stack } from "@mui/material";
import {
  useMutationBulkDeleteCustomer,
  // useMutationDeleteCustomer,
  useQueryCustomers
} from "../../api/Customer";
// import useAuth from "../../hooks/useAuth";

import { withSearchQueryProvider } from "../../contexts/SeachQueryContext";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import DataGridPage from "../../components/data-grid/DataGridPage";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
// import useHealthChecker from "../../hooks/useHealthChecker";

const CustomersList = () => {
  useSetPageTitle("Customers");

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      resizable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;
        const { uuid } = row;

        return (
          <Stack alignItems={"flex-start"}>
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", { uuid })}
            >
              {value}
            </Link>

            {!!row?.is_tester && (
              <Chip label="Tester" size="small" color="warning" sx={{ mt: 1 }} />
            )}
          </Stack>
        );
      }
    },

    {
      field: "company_name",
      headerName: "Name",
      resizable: true,
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        const { uuid } = row;
        const userInfo = row.company_name
          ? `${row.company_name}, ${row.first_name} ${row.last_name}`
          : `${row.first_name} ${row.last_name}`;
        return (
          <Stack alignItems={"flex-start"}>
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", { uuid })}
            >
              {userInfo}
            </Link>
          </Stack>
        );
      }
    },

    // { field: "company_name", headerName: "Company" },
    // { field: "first_name", headerName: "First Name" },
    // { field: "last_name", headerName: "Last Name" },
    { field: "phone_number", headerName: "Phone #" },
    { field: "mobile_number", headerName: "Mobile #" },
    { field: "billing_address", headerName: "Billing Address" },
    {
      field: "customer_group",
      headerName: "Customer Group",
      resizable: true,
      renderCell: params => {
        const {
          value: customerGroupName,
          row: { customer_group }
        } = params;

        if (customer_group) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customer-groups/:uuid/edit", {
                uuid: customer_group?.uuid
              })}
            >
              {customerGroupName}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.customer_group?.name
    },

    { field: "customer_type", headerName: "Account Type" }
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   filterable: false,
    //   sortable: false,
    //   headerAlign: "right",
    //   minWidth: 150,
    //   align: "right",
    //   renderCell: ({ row }) => {
    //     const { uuid } = row;

    //     return [
    //       <Tooltip title="Details" key={`details-${uuid}`}>
    //         <IconButton
    //           size="large"
    //           component={RouterLink}
    //           to={generatePath("/customers/:uuid/details", { uuid })}
    //           disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
    //         >
    //           <EyeIcon />
    //         </IconButton>
    //       </Tooltip>,
    //       <Tooltip title="Edit" key={`edit-${uuid}`}>
    //         <IconButton
    //           size="large"
    //           component={RouterLink}
    //           to={generatePath("/customers/:uuid/edit", { uuid })}
    //           disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       </Tooltip>,
    //       <Tooltip title="Delete" key={`delete-${uuid}`}>
    //         <IconButton
    //           size="large"
    //           onClick={async () => {
    //             if (
    //               await confirm({
    //                 confirmation: "You are about to delete. Are you sure?",
    //                 options: {
    //                   title: "Confirm"
    //                 }
    //               })
    //             ) {
    //               await deleteItem(uuid);
    //             }
    //           }}
    //           disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Tooltip>
    //     ];
    //   },
    //   flex: 1
    // }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withSearchQueryProvider(
  withDataGridPageProvider(CustomersList, {
    useQueryList: useQueryCustomers,
    useMutationBulkDelete: useMutationBulkDeleteCustomer,
    addButtonNavigateTo: "/customers/add"
  })
);
