import {
  useMutationSaveOrderFitting,
  useQueryOrderFittingFormOptions
} from "../../../api/OrderFitting.ts";
import { debounce } from "lodash";
import { SelectInput } from "./SelectInput.tsx";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { memo, useState } from "react";
import { v4 } from "uuid";

export const ErrorReasonInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    error_reason_uuid
  }: {
    order_uuid: string;
    uuid?: string;
    error_reason_uuid?: string;
  }) => {
    const [value, setValue] = useState(error_reason_uuid);

    const { data: formOptions } = useQueryOrderFittingFormOptions();
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = debounce(async (event: SelectChangeEvent<any>) => {
      setValue(event.target.value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        error_reason_uuid: event.target.value || null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    }, 100);

    return (
      <SelectInput
        title="Error Reason"
        isLoading={isLoading}
        onChange={handleOnChange}
        value={value}
        formOptions={formOptions?.error_reasons?.map(reason => [reason.uuid, reason.name])}
      />
    );
  }
);
