import { parseISO } from "date-fns";
import { DateInput } from "./DateInput.tsx";
import { useMutationSaveOrderFitting } from "../../../api/OrderFitting.ts";
import { debounce } from "lodash";
import { useState } from "react";
import { v4 } from "uuid";

const JobBookedDateCell = ({
  uuid: _uuid,
  order_uuid,
  job_booked_at
}: {
  order_uuid: string;
  uuid?: string;
  job_booked_at?: string;
}) => {
  const [value, setValue] = useState<Date | null>(job_booked_at ? parseISO(job_booked_at) : null);
  const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

  const uuid = _uuid ?? v4();

  const handleOnChange = debounce(async (_value: Date | null) => {
    setValue(_value);
    const prev = value;
    saveOrderFitting({
      uuid,
      order_uuid,
      job_booked_at: _value
    }).catch(() => setTimeout(() => setValue(prev), 500));
  }, 100);

  return (
    <DateInput
      title="Job Booked Date"
      isLoading={isLoading}
      onChange={handleOnChange}
      value={value}
      disableRemove
    />
  );
};

// JobBookedDateCell.whyDidYouRender = true;

export { JobBookedDateCell };
