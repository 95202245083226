import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { outstanding_order, useQueryCustomerStatistics } from "../../api/Customer";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfToday, format, parseISO } from "date-fns";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);
const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
  min-height: 26px;

  & > .MuiAccordionSummary-content {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

function CustomerStatisticsCard({ customer_uuid }: { customer_uuid?: string }) {
  const [asOfDate, setAsOfDate] = useQueryParam(
    "asOfDate",
    withDefault(StringParam, endOfToday().toISOString())
  );
  const { data: statistics } = useQueryCustomerStatistics(customer_uuid, parseISO(asOfDate));

  return (
    <CardContent>
      <List sx={{ width: 300 }} dense>
        <ListItem>
          <DatePicker
            label="As at"
            value={parseISO(asOfDate)}
            onChange={value => value && setAsOfDate(value.toISOString())}
            slotProps={{
              field: {
                InputProps: {
                  // @ts-expect-error this works
                  disableUnderline: true
                }
              },
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard"
              }
            }}
          />
        </ListItem>
        <ListItem
          secondaryAction={
            <Typography>{toDecimalFromFloat(statistics?.excluding_gst ?? 0)}</Typography>
          }
        >
          <ListItemText primary="Customer LTV ex GST:" />
        </ListItem>

        {/* <ListItem>
          <ListItemText primary="" />
        </ListItem> */}

        <ListItem sx={{ borderRadius: "5px 5px 5px 5px", border: "1px solid", marginTop: 4 }}>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>Balance of Account:</span>

              <span>{toDecimalFromFloat(statistics?.balance?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {!statistics?.balance?.outstanding_orders && (
                <Typography variant="caption">No outstanding orders</Typography>
              )}

              <ListOutstandingOrders
                outstanding_orders={statistics?.balance?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>Current:</span>

              <span>{toDecimalFromFloat(statistics?.invoiced_current?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOutstandingOrders
                outstanding_orders={statistics?.invoiced_current?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>30 Days:</span>

              <span>{toDecimalFromFloat(statistics?.invoiced_30_days?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOutstandingOrders
                outstanding_orders={statistics?.invoiced_30_days?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>60 Days:</span>

              <span>{toDecimalFromFloat(statistics?.invoiced_60_days?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOutstandingOrders
                outstanding_orders={statistics?.invoiced_60_days?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>90 Days:</span>

              <span>{toDecimalFromFloat(statistics?.invoiced_90_days?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOutstandingOrders
                outstanding_orders={statistics?.invoiced_90_days?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>90+ Days:</span>

              <span>{toDecimalFromFloat(statistics?.invoiced_90_plus_days?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOutstandingOrders
                outstanding_orders={statistics?.invoiced_90_plus_days?.outstanding_orders ?? []}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </List>

      {/*<Stack direction="row" justifyContent="space-between" alignItems="center">*/}
      {/*  <Stack direction="column">*/}
      {/*    {!!statistics?.excluding_gst && (*/}
      {/*      <Typography gutterBottom variant="h3" component="div">*/}
      {/*        {statistics?.excluding_gst}*/}
      {/*      </Typography>*/}
      {/*    )}*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
    </CardContent>
  );
}

export default CustomerStatisticsCard;

const ListOutstandingOrders = ({
  outstanding_orders
}: {
  outstanding_orders: outstanding_order[];
}) => (
  <List dense disablePadding>
    {outstanding_orders?.map(row => {
      return (
        <ListItem
          key={row.order_uuid}
          disablePadding
          secondaryAction={
            <Stack direction="column">
              <span>{toDecimalFromFloat(row.outstanding ?? 0)}</span>
            </Stack>
          }
        >
          <ListItemText
            primary={
              <Stack direction="column">
                <Link
                  component={RouterLink}
                  to={generatePath("/order/:uuid/finalized-quote", {
                    uuid: row.order_uuid
                  })}
                >
                  {row.reference_number}
                </Link>
                <span>Invoiced: {format(parseISO(row.is_invoiced_at), "dd/MM/yyyy")}</span>
              </Stack>
            }
          />
        </ListItem>
      );
    })}
  </List>
);
