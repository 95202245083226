import {
  useMutationSaveOrderFitting,
  useQueryOrderFittingFormOptions
} from "../../../api/OrderFitting.ts";
import { SelectInput } from "./SelectInput.tsx";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { memo, useState } from "react";
import { v4 } from "uuid";

export const JobBookedTimeSlotInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    job_booked_time_slot
  }: {
    order_uuid: string;
    uuid?: string;
    job_booked_time_slot?: string;
  }) => {
    const [value, setValue] = useState<string | undefined>(job_booked_time_slot);
    const { data: formOptions } = useQueryOrderFittingFormOptions();
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = async (event: SelectChangeEvent<any>) => {
      setValue(event.target.value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        job_booked_time_slot: event.target.value || null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    };

    return (
      <SelectInput
        title="Time Slot"
        isLoading={isLoading}
        onChange={handleOnChange}
        value={value}
        formOptions={formOptions?.job_booked_time_slots?.map(timeslot => [timeslot, timeslot])}
      />
    );
  }
);
