import { useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";
import { useMutationAbstract } from "../hooks/useMutationFormAbstract";

export const useQueryAuthUser = (accessToken: string | null) => {
  return useQuery({
    queryKey: ["auth", "user-me", { accessToken }],
    useErrorBoundary: false,
    suspense: true,
    queryFn: ({ signal }) =>
      axios
        .get("/api/auth/users/me", {
          signal,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(({ data }) => data),
    retry: false
  });
};

export const useAuthUserLoginEmailPassword = () => {
  return useMutationAbstract({
    mutationFn: data =>
      axios
        .post("/api/auth/users/login", {
          ...data
        })
        .then(({ data }) => data)
  });
};
export const useAuthUserLogout = () => {
  return useMutationAbstract({
    mutationFn: () => axios.post("/api/auth/users/logout").then(({ data }) => data)
  });
};
