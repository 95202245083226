import React, { useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { generatePath, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Chip as MuiChip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { useMutationBulkDeleteLead, useQueryLeads } from "../../api/Lead";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext";
import { addDays, compareAsc, compareDesc, format } from "date-fns";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridRenderEditCellParams
} from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import { DataGridPageProvider } from "../../contexts/DataGridPageContext";
import LeadStatusGridAutocompleteField from "./data-grid/LeadStatusGridAutocompleteField";
import SalesRepGridAutocompleteField from "./data-grid/SalesRepGridAutocompleteField";
import { DateRangePicker, Range } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import useHealthChecker from "../../hooks/useHealthChecker";
import { useAuthAbility } from "../../hooks/useAuthAbility";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const Chip = styled(MuiChip)(spacing);

// TODO: Separate component file
const LightTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

const DateRangeInputValue = ({ item, applyValue }: GridFilterInputValueProps) => {
  const { value } = item;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selection, setSelection] = useState<Range>({
    startDate: value?.startDate ? new Date(value?.startDate) : new Date(),
    endDate: value?.endDate ? new Date(value?.endDate) : addDays(new Date(), 7),
    key: "selection"
  });

  const handleSetValue = () => {
    applyValue({ ...item, value: selection });
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        Click to set Date Range
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Set Date Range</DialogTitle>
        <DialogContent>
          <DateRangePicker
            onChange={({ selection }) => setSelection(selection)}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[selection]}
            direction="horizontal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSetValue}>Set</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DateRangeOperator: GridFilterOperator = {
  label: "Date Range",
  value: "date_range",
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value) {
      return null;
    }

    return (value, row): boolean => {
      const { appointment_datetime_start } = row;
      return (
        compareAsc(new Date(appointment_datetime_start), new Date(filterItem.value.startDate)) >=
          0 &&
        compareDesc(new Date(appointment_datetime_start), new Date(filterItem.value.endDate)) >= 0
      );
    };
  },
  InputComponent: DateRangeInputValue,
  InputComponentProps: { type: "number" }
  // getValueAsString: (value: number) => `${value} Stars`,
};

const LeadList = () => {
  const ability = useAuthAbility();
  const { isSuspendMutations } = useHealthChecker();

  useSetPageTitle("Leads");

  const columns: GridColDef[] = [
    {
      field: "lead_number",
      headerName: "Lead number",
      width: 100,
      renderCell: params => {
        const {
          row: { lead_number, uuid }
        } = params;

        return (
          <Link
            component={RouterLink}
            underline="none"
            to={generatePath("/lead/:uuid/details", { uuid })}
          >
            {lead_number}
          </Link>
        );
      }
    },
    {
      field: "customer_email",
      headerName: "Customer Email",
      width: 250,
      renderCell: params => {
        const {
          value: customerEmail,
          row: { customer }
        } = params;

        if (!customer?.uuid) {
          return;
        }

        if (ability.cannot("UpdateCustomer", "customers")) {
          return customerEmail;
        }

        return (
          <Link
            component={RouterLink}
            underline="none"
            to={generatePath("/customers/:uuid/details", {
              uuid: customer?.uuid
            })}
          >
            {customerEmail}
          </Link>
        );
      },
      // for sorting
      valueGetter: (value, row) => {
        const { customer } = row;
        return customer?.email;
      }
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      renderCell: params => {
        const {
          value: customerName,
          row: { customer }
        } = params;

        if (ability.cannot("Read", "customers")) {
          return customerName;
        }

        if (customer) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", {
                uuid: customer?.uuid
              })}
            >
              {customerName}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => {
        const { customer } = row;
        return [customer?.first_name, customer?.last_name].filter(value => value).join(" ");
      }
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
      valueGetter: (value, row) => {
        const { customer } = row;
        return customer?.phone_number;
      }
    },
    {
      field: "site_address",
      headerName: "Site Address",
      width: 500,
      valueGetter: (value, row) => {
        const { customer, site_address } = row;
        return site_address ?? `${customer?.billing_address} (From customer's billing address)`;
      }
    },
    {
      field: "lead_source",
      headerName: "Lead Source",
      width: 150,
      renderCell: ({ row }) => {
        const { lead_source } = row;

        if (lead_source) {
          if (ability.cannot("UpdateLeadSource", "lead_source")) {
            return lead_source?.name;
          }

          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/lead-source/:uuid/edit", {
                uuid: lead_source?.uuid
              })}
            >
              {lead_source?.name}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => {
        const { lead_source } = row;
        return lead_source?.name;
      }
    },
    {
      field: "marketings",
      headerName: "Marketing",
      width: 300,
      renderCell: ({ row }) => {
        const { marketings } = row;

        if (!marketings || marketings.length < 1) {
          return;
        }
        return Array.prototype.map
          .call(marketings, function (item) {
            return item.name;
          })
          .join(", ");
      }
    },
    {
      field: "lead_date",
      headerName: "Lead Date",

      renderCell: ({ row }) => {
        if (row.appointment_datetime_start === null) {
          return;
        }

        return format(new Date(row.appointment_datetime_start), "dd/MM/yyyy");
      },
      valueGetter: (value, row) => {
        if (row.appointment_datetime_start === null) {
          return;
        }

        return new Date(row.appointment_datetime_start);
      },
      filterOperators: [DateRangeOperator]
    },
    {
      field: "lead_date_start_time",
      headerName: "Start Time",
      minWidth: 80,

      renderCell: ({ row }) => {
        if (row.appointment_datetime_start === null) {
          return;
        }

        return format(new Date(row.appointment_datetime_start), "p");
      },
      valueGetter: (value, row) => {
        if (row.appointment_datetime_start === null) {
          return;
        }

        return format(new Date(row.appointment_datetime_start), "kk:mm:ss");
      }
    },
    {
      field: "lead_date_end_time",
      headerName: "End Time",
      minWidth: 80,

      renderCell: ({ row }) => {
        if (row.appointment_datetime_end === null) {
          return;
        }

        return format(new Date(row.appointment_datetime_end), "p");
      },
      valueGetter: (value, row) => {
        if (row.appointment_datetime_end === null) {
          return;
        }

        return format(new Date(row.appointment_datetime_end), "kk:mm:ss");
      }
    },
    {
      field: "lead_status_name",
      headerName: "Lead Status",
      width: 160,
      renderCell: params => {
        const { row, id, field, api } = params;
        const { lead_status } = row;

        if (!lead_status?.uuid) {
          return;
        }

        if (ability.cannot("UpdateLeadStatus", "lead_status")) {
          return lead_status?.name;
        }

        return (
          <LightTooltip
            placement="right-start"
            title={
              <MenuList>
                <MenuItem
                  onClick={() => api.startCellEditMode({ id, field })}
                  disabled={isSuspendMutations}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Modify</ListItemText>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*  <ListItemIcon>*/}
                {/*    <OpenInNewIcon fontSize="small" />*/}
                {/*  </ListItemIcon>*/}
                {/*  <ListItemText>Open</ListItemText>*/}
                {/*</MenuItem>*/}
              </MenuList>
            }
          >
            <Chip
              label={
                <Link
                  component={RouterLink}
                  underline="none"
                  to={generatePath("/lead-status/:uuid/edit", {
                    uuid: lead_status?.uuid
                  })}
                >
                  <Typography
                    sx={{
                      color: `#000000`
                    }}
                  >
                    {lead_status?.name}
                  </Typography>
                </Link>
              }
              key={row.uuid}
              m={1}
              sx={{
                backgroundColor: lead_status?.color,
                borderRadius: 12
              }}
            />
          </LightTooltip>
        );
      },
      // for sorting
      valueGetter: (value, row) => row?.lead_status?.name,
      editable: true,
      renderEditCell: (props: GridRenderEditCellParams) => (
        <LeadStatusGridAutocompleteField {...props} />
      )
    },
    {
      field: "sales_rep",
      headerName: "Sales Rep",
      renderCell: params => {
        const { value: salesRepName, row, id, field, api } = params;

        const { sales_rep_user } = row;

        if (!sales_rep_user?.uuid) {
          return;
        }

        if (ability.cannot("UpdateUser", "users")) {
          return salesRepName;
        }

        return (
          <LightTooltip
            placement="right-start"
            title={
              <MenuList>
                <MenuItem
                  onClick={() => api.startCellEditMode({ id, field })}
                  disabled={isSuspendMutations}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Modify</ListItemText>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*  <ListItemIcon>*/}
                {/*    <OpenInNewIcon fontSize="small" />*/}
                {/*  </ListItemIcon>*/}
                {/*  <ListItemText>Open</ListItemText>*/}
                {/*</MenuItem>*/}
              </MenuList>
            }
          >
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/users/:uuid/edit", {
                uuid: sales_rep_user?.uuid
              })}
            >
              {salesRepName}
            </Link>
          </LightTooltip>
        );
      },
      // for sorting
      valueGetter: (value, row) => {
        const { sales_rep_user } = row;
        const fullName = [sales_rep_user?.first_name, sales_rep_user?.last_name]
          .filter(value => value)
          .join(" ");
        return fullName ? fullName : sales_rep_user?.email;
      },
      editable: true,
      renderEditCell: (props: GridRenderEditCellParams) => (
        <SalesRepGridAutocompleteField {...props} />
      )
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300
    },
    {
      field: "createdAt",
      headerName: "Created",
      width: 160,
      renderCell: ({ row }) => {
        if (row.createdAt === null) {
          return;
        }

        return format(new Date(row.createdAt), "dd/MM/yyyy p");
      },
      valueGetter: (value, row) => {
        if (row.createdAt === null) {
          return;
        }

        return new Date(row.createdAt);
      },
      filterOperators: [DateRangeOperator]
    }
    /*   {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      minWidth: 150,
      align: "right",
      renderCell: ({ row }) => {
        const { uuid } = row;
        return [
          <Can I="Read" a="lead" key={`details-${uuid}`}>
            <Tooltip title="Details">
              <IconButton
                size="small"
                component={RouterLink}
                to={generatePath("/lead/:uuid/details", { uuid })}
              >
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </Can>,
          <Can I="UpdateLead" a="lead" key={`edit-${uuid}`}>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                disabled={isSuspendMutations}
                component={RouterLink}
                to={generatePath("/lead/:uuid/edit", { uuid })}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Can>,
          <Can I="DeleteLead" a="lead" key={`delete-${uuid}`}>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                disabled={isSuspendMutations}
                onClick={async () => {
                  if (
                    await confirm({
                      confirmation: "You are about to delete. Are you sure?",
                      options: {
                        title: "Confirm"
                      }
                    })
                  ) {
                    await deleteItem(uuid);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Can>
        ];
      }
    }  */
  ];

  return (
    <DataGridPageProvider
      useQueryList={useQueryLeads}
      useMutationBulkDelete={
        ability.can("DeleteLead", "lead") ? useMutationBulkDeleteLead : undefined
      }
      addButtonNavigateTo={ability.can("CreateLead", "lead") ? "/lead/add" : undefined}
    >
      <DataGridPage
        dataGridProps={{
          columns,
          checkboxSelection: ability.can("DeleteLead", "lead")
        }}
      />
    </DataGridPageProvider>
  );
};

export const LeadListComponent = LeadList;

export default withSearchQueryProvider(LeadList);
