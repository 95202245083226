import React from "react";
import {
  generatePath,
  NavigateOptions,
  Outlet,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { Alert as MuiAlert, AlertTitle, Step, StepButton, Stepper, styled } from "@mui/material";
import { MutationResultType } from "../../../hooks/useMutationFormAbstract";
import { useQueryOneOrder } from "../../../api/Order";
import { spacing } from "@mui/system";
import EventsTimelineCard from "../../customer/EventLogsCard.tsx";
import { Can } from "../../../casl.ts";
import { useFeature } from "use-feature";

const Alert = styled(MuiAlert)(spacing);

export interface OrderFormOutletContext {
  mutationOrder: MutationResultType;
  order_uuid?: string;
  isEditing: boolean;
  navigateToStep: (step: string, _uuid?: string, opts?: NavigateOptions) => void;
}

const OrderForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = props => {
  const { uuid } = useParams();
  const { data: order } = useQueryOneOrder(uuid);
  const isEditing = !!uuid;
  const isFinalized = false;
  const navigate = useNavigate();
  const location = useLocation();
  const stepName = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

  const debug = useFeature("FEATURE_DEBUG_ORDER");

  const navigateToStep = (step: string, _uuid?: string, opts?: NavigateOptions) => {
    const finalUuid = _uuid ?? uuid;
    finalUuid && navigate(generatePath("/order/:uuid/edit/:step", { uuid: finalUuid, step }), opts);
  };

  return (
    <>
      <Stepper nonLinear>
        <Step active={stepName === "step-1"} completed={isEditing}>
          <StepButton color="inherit" onClick={() => uuid && navigateToStep("step-1", uuid)}>
            Info
          </StepButton>
        </Step>
        <Step active={stepName === "step-2"} completed={isFinalized}>
          <StepButton color="inherit" onClick={() => uuid && navigateToStep("step-2", uuid)}>
            Select Products
          </StepButton>
        </Step>
        <Step active={stepName === "step-3"} completed={isFinalized}>
          <StepButton color="inherit" onClick={() => uuid && navigateToStep("step-3", uuid)}>
            Cart {!!order?.order_line_items && `(${order?.order_line_items?.length})`}
          </StepButton>
        </Step>
        <Step last disabled>
          <StepButton color="inherit">Final</StepButton>
        </Step>
      </Stepper>

      {Boolean(order?.is_test_mode) && stepName !== "step-1" && (
        <Alert severity="warning" mt={4}>
          <AlertTitle>TEST ORDER</AlertTitle>
          Payments will be marked as TEST mode.
        </Alert>
      )}

      <Outlet
        context={{
          mutationOrder: props.mutation,
          order_uuid: props.uuid,
          navigateToStep,
          isEditing
        }}
      />

      {debug && (
        <Can I="Read" a="event_store">
          <EventsTimelineCard stream="orders" aggregate_id={uuid} />
        </Can>
      )}
    </>
  );
};

export default OrderForm;
