import React, { useCallback, useMemo } from "react";
import { Formik, useField } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  FormControl,
  Grid,
  TextField as MuiTextField,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import { array } from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { QUERY_KEY, useQueryOneProduct, useQueryProductFormOptions } from "../../api/Product";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import AutocompleteField from "../../components/AutocompleteField";
import SelectInventoryItemsTreeView from "./SelectInventoryItemsTreeView";
import { DataGridPro, GridColDef, GridRowModel } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  code: string;
  description?: string;
  status?: string;
  product_line_uuid?: string;
  inventory_item_uuids?: string[];
  customer_groups_markup_percentage?: {
    customer_group_uuid: string;
    markup_percentage?: number | null;
  }[];
}

const ProductForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
  isEdit?: boolean;
}> = ({ mutation, uuid }) => {
  const { data } = useQueryOneProduct(uuid);
  const { data: formOptions } = useQueryProductFormOptions();
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();

  const productLineOptions =
    formOptions?.product_lines?.map((item: any) => {
      return {
        label: item.name,
        id: item.uuid
      };
    }) ?? [];

  const customerGroupUuids = formOptions?.customer_groups?.map(({ uuid }: any) => uuid) ?? [];

  const validationSchema = Yup.object().shape({
    code: Yup.string().required().max(255),
    description: Yup.string().max(255).optional().nullable(),
    status: Yup.string().max(255).optional().nullable(),
    product_line_uuid: Yup.string()
      .uuid()
      .required()
      .label("Line")
      .nullable()
      .oneOf(productLineOptions.map(({ id }: any) => id)),
    inventory_item_uuids: array()
      .of(
        Yup.string()
          .uuid()

          .oneOf(formOptions?.inventory_items?.map(({ uuid }: any) => uuid) ?? [])
      )
      .label("Inventory Items"),
    customer_groups_markup_percentage: array()
      .of(
        Yup.object({
          customer_group_uuid: Yup.string().uuid().oneOf(customerGroupUuids),
          markup_percentage: Yup.number().optional().nullable()
        })
      )
      .optional()
      .nullable()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/product",
    validationSchema
  });

  return (
    <Formik
      initialValues={{
        code: "",
        description: "",
        product_line_uuid: null,
        ...data,
        customer_groups_markup_percentage:
          data?.customer_groups_markup_percentage?.filter(({ customer_group_uuid }: any) =>
            customerGroupUuids?.includes(customer_group_uuid)
          ) ?? null,
        inventory_item_uuids: data?.inventory_items?.map(({ uuid }: any) => uuid) ?? []
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty, setValues }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="code"
                label="Inventory Code"
                value={values.code ?? ""}
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="description"
                label="Description"
                value={values.description ?? ""}
                error={Boolean(touched.code && errors.description)}
                fullWidth
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <AutocompleteField
                label="Choose product line"
                name="product_line_uuid"
                options={productLineOptions}
                fullWidth
                openOnFocus
                disabled={isLoading}
              />

              <Card my={3}>
                <Typography variant="subtitle2" mb={3}>
                  Markup Percentages
                </Typography>
                <CustomerGroupsMarkupPercentageInput />
              </Card>

              <FormControl fullWidth disabled={isLoading} margin="normal">
                <Card>
                  <Typography variant="subtitle2" mb={3}>
                    Inventory Items
                  </Typography>
                  <SelectInventoryItemsTreeView
                    name="inventory_item_uuids"
                    inventory_items={formOptions?.inventory_items}
                  />
                </Card>
              </FormControl>

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => ({
                        ...prevValues,
                        code: faker.random.alphaNumeric(10),
                        description: faker.lorem.paragraph(),
                        product_line_uuid: faker.helpers.arrayElement(
                          productLineOptions.map(({ id }: any) => id)
                        )
                      }));
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default ProductForm;

const CustomerGroupsMarkupPercentageInput = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField("customer_groups_markup_percentage");
  const { enqueueSnackbar } = useSnackbar();
  const { data: formOptions } = useQueryProductFormOptions();

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      helpers.setValue([
        ...(field.value?.filter(
          ({ customer_group_uuid }: any) => customer_group_uuid !== newRow.customer_group_uuid
        ) ?? []),
        newRow
      ]);

      return newRow;
    },
    [field.value]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    enqueueSnackbar(`Something went wrong: ${error.message}`, {
      variant: "error",
      preventDuplicate: true
    });
  }, []);

  const rows = useMemo(() => {
    return formOptions?.customer_groups?.map((customerGroup: any) => {
      return {
        customer_group_uuid: customerGroup.uuid,
        name: customerGroup.name,
        markup_percentage: field.value?.find(
          ({ customer_group_uuid }: any) => customer_group_uuid === customerGroup.uuid
        )?.markup_percentage
      };
    });
  }, [formOptions?.customer_groups, field.value]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Customer Group", flex: 1 },
    {
      field: "markup_percentage",
      headerName: "Markup %",
      type: "number",
      editable: true,
      flex: 1,
      headerAlign: "left",
      align: "left"
    }
  ];

  // TODO: Remove DataGridPro pagination
  return (
    <DataGridPro
      density="compact"
      getRowId={({ customer_group_uuid }) => customer_group_uuid}
      rows={rows}
      columns={columns}
      processRowUpdate={processRowUpdate}
      autoHeight
      disableColumnFilter
      disableRowSelectionOnClick
      onProcessRowUpdateError={handleProcessRowUpdateError}
    />
  );
};
