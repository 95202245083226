import {
  useMutationSaveOrderFitting,
  useQueryOrderFittingFormOptions
} from "../../../api/OrderFitting.ts";
import { debounce } from "lodash";
import { SelectInput } from "./SelectInput.tsx";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { memo, useState } from "react";
import { v4 } from "uuid";

export const JobTypeCell = memo(
  ({
    uuid: _uuid,
    order_uuid,
    job_type
  }: {
    order_uuid: string;
    uuid?: string;
    job_type: string;
  }) => {
    // TODO default here
    const [value, setValue] = useState<string | undefined>(_uuid ? job_type : "install");
    const { data: formOptions } = useQueryOrderFittingFormOptions();
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

    const uuid = _uuid ?? v4();

    const handleOnChange = debounce(async (event: SelectChangeEvent<any>) => {
      setValue(event.target.value);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        job_type: event.target.value || null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    }, 100);

    return (
      <SelectInput
        title="Job Type"
        isLoading={isLoading}
        onChange={handleOnChange}
        value={value}
        formOptions={formOptions?.job_types?.map(job_type => [job_type, job_type])}
      />
    );
  }
);
