import { useContext, useMemo } from "react";
import { SidebarItemsType } from "../types/sidebar";
import { AbilityContext } from "../casl";
import dashboardItems from "../components/sidebar/dashboardItems";
import useAuth from "./useAuth";

export const useSidebarItems = () => {
  const { user } = useAuth();
  const ability = useContext(AbilityContext);

  const reduceChildren: any = (pages: SidebarItemsType[], page: SidebarItemsType) => {
    if (page.children) {
      const children: any = page.children?.reduce(reduceChildren, []);
      return [
        ...pages,
        children?.length > 0 && {
          ...page,
          children
        }
      ].filter(Boolean);
    }

    if (ability.can("ReadList", (page.permissionSubject as any) ?? "all")) {
      return [...pages, page];
    }

    return pages;
  };

  return useMemo(() => {
    return dashboardItems.reduce((sections: any[], section) => {
      return [
        ...sections,
        {
          ...section,
          pages: section.pages.reduce(reduceChildren, [])
        }
      ];
    }, []);
  }, [ability.rules, dashboardItems, user]);
};
