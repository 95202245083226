import { Card, TextField } from "@mui/material";
import { useQueryOrderFittingsSummary } from "../../api/OrderFitting.ts";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";

export const Summary = () => {
  const { data: summary } = useQueryOrderFittingsSummary();

  return (
    <Card sx={{ display: "flex", justifyContent: "space-between", padding: 3 }}>
      <TextField
        label="Confirmed"
        value={toDecimalFromFloat(summary?.confirmed ?? 0)}
        InputLabelProps={{
          shrink: true
        }}
        variant="standard"
        InputProps={{ readOnly: true }}
      />

      <TextField
        label="Unconfirmed"
        value={toDecimalFromFloat(summary?.unconfirmed ?? 0)}
        InputLabelProps={{
          shrink: true
        }}
        variant="standard"
        InputProps={{ readOnly: true }}
      />

      <TextField
        label="Total"
        value={toDecimalFromFloat(summary?.total ?? 0)}
        InputLabelProps={{
          shrink: true
        }}
        variant="standard"
        InputProps={{ readOnly: true }}
      />
    </Card>
  );
};
