import { useMutationSaveOrderFitting } from "../../../api/OrderFitting";
import { memo, useState } from "react";
import { Checkbox } from "@mui/material";
import useHealthChecker from "../../../hooks/useHealthChecker";
import { v4 } from "uuid";

export const CuttingSheetProcessedAtInput = memo(
  ({
    uuid: _uuid,
    order_uuid,
    cutting_sheet_processed_at
  }: {
    order_uuid: string;
    uuid?: string;
    cutting_sheet_processed_at?: string;
  }) => {
    const [value, setValue] = useState<boolean>(!!cutting_sheet_processed_at);
    const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();
    const { isSuspendMutations } = useHealthChecker();

    const uuid = _uuid ?? v4();

    const handleOnChange = async (event: any, checked: boolean) => {
      setValue(checked);
      const prev = value;
      saveOrderFitting({
        uuid,
        order_uuid,
        cutting_sheet_processed_at: checked ? new Date() : null
      }).catch(() => setTimeout(() => setValue(prev), 500));
    };

    return (
      <Checkbox
        disabled={isSuspendMutations || isLoading}
        checked={value}
        value={true}
        onChange={handleOnChange}
      />
    );
  }
);
