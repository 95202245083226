import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

import authRoutes from "./pages/auth";
import Page404 from "./pages/errors/Page404";
import customerRoutes from "./pages/customer";
import logEmailRoutes from "./pages/log_email";
import customerGroupRoutes from "./pages/customer_group";
import leadSourceRoutes from "./pages/lead_source";
import leadStatusRoutes from "./pages/lead_status";
import marketingRoutes from "./pages/marketing";
import leadRoutes from "./pages/lead";
import productLineRoutes from "./pages/product_line";
import productSoldByRoutes from "./pages/product_sold_by";
import inventoryItemRoutes from "./pages/inventory_item";
import productRoutes from "./pages/product";
import orderStatusRoutes from "./pages/order_status";
import keyValueRoutes from "./pages/key_value";
import userRoutes from "./pages/user";
import orderRoutes from "./pages/order";
import orderLineItemRoutes from "./pages/order_line_item";
import eventStoreRoutes from "./pages/event_store";
import paymentMethodRoutes from "./pages/payment_method";
import bankReconciliationRoutes from "./pages/bank_reconciliation";
import pagesRoutes from "./pages/pages";
import accountGroupRoutes from "./pages/account_group";
import companyRoutes from "./pages/company";
import outstandingOrdersRoutes from "./pages/outstanding_orders";
import paymentTermConfigRoutes from "./pages/payment_term_config";
import orderReceivableRoutes from "./pages/order_receivable";
import userRoleRoutes from "./pages/user_role";
import orderFittingRoutes from "./pages/order_fitting";
import dataExportRoutes from "./pages/data_export";
import OrderQuoteFinalized from "./pages/order/public/OrderQuoteFinalized";
import PublishedPage from "./pages/publish_pages/PublishedPage";
import { PrintReceipt } from "./pages/order_transaction/PrintReceipt";
import { DashboardV2 } from "./layouts/DashboardV2.tsx";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardV2 />
      </AuthGuard>
    ),
    children: [
      ...userRoutes,
      ...customerGroupRoutes,
      ...customerRoutes,
      ...leadStatusRoutes,
      ...leadSourceRoutes,
      ...marketingRoutes,
      ...leadRoutes,
      ...productLineRoutes,
      ...productSoldByRoutes,
      ...inventoryItemRoutes,
      ...productRoutes,
      ...orderStatusRoutes,
      ...orderRoutes,
      ...orderLineItemRoutes,
      ...keyValueRoutes,
      ...logEmailRoutes,
      ...eventStoreRoutes,
      ...paymentMethodRoutes,
      ...accountGroupRoutes,
      ...companyRoutes,
      ...bankReconciliationRoutes,
      ...pagesRoutes,
      ...outstandingOrdersRoutes,
      ...paymentTermConfigRoutes,
      ...orderReceivableRoutes,
      ...userRoleRoutes,
      ...orderFittingRoutes,
      ...dataExportRoutes
    ]
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [...authRoutes]
  },
  {
    path: "/guest",
    element: <PresentationLayout />,
    children: [
      {
        path: "order/:uuid",
        element: <OrderQuoteFinalized />
      },
      {
        path: "order-transaction/:uuid/print-receipt",
        element: <PrintReceipt />
      }
    ]
  },
  {
    path: "pages/:slug",
    element: (
      <PresentationLayout>
        <PublishedPage />
      </PresentationLayout>
    )
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />
      }
    ]
  }
];

export default routes;
