import OrderList from "./OrderList";
import OrderAdd from "./OrderAdd";
import OrderEdit from "./OrderEdit";
import { RouteObject } from "react-router-dom";
import FormStep1 from "./form/FormStep1";
import FormStep2 from "./form/FormStep2";
import FormStep3 from "./form/FormStep3";
import OrderLineItemForm from "./form/OrderLineItemForm";
import CartSummary from "./form/CartSummary";
import OrderQuoteFinalized from "./OrderQuoteFinalized";

const routes: RouteObject[] = [
  {
    path: "",
    element: <OrderList />
  },
  {
    path: "/order",
    element: <OrderList />
  },
  {
    path: "/order/add",
    element: <OrderAdd />,
    children: [
      {
        path: "step-1",
        element: <FormStep1 />
      }
    ]
  },
  {
    path: "/order/:uuid/edit",
    element: <OrderEdit />,
    children: [
      {
        path: "",
        element: <FormStep1 />
      },
      {
        path: "step-1",
        element: <FormStep1 />
      },
      {
        path: "step-2",
        element: <FormStep2 />,
        children: [
          {
            path: "",
            element: <OrderLineItemForm />
          }
        ]
      },
      {
        path: "step-3",
        element: <FormStep3 />,
        children: [
          {
            path: "",
            element: <CartSummary />
          },
          {
            path: "line-item/:order_line_item_uuid",
            element: <OrderLineItemForm />
          }
        ]
      }
    ]
  },
  {
    path: "/order/:uuid/finalized-quote",
    element: <OrderQuoteFinalized />
  }
];

export default routes;
