import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
// import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem";
// import { createConfirmation } from "../../components/ReactConfirmMountPoint";
// import Loader from "../../components/Loader";
// import { AccordionOrderLineItem } from "./components/AccordionOrderLineItem";

export const OrderNewVariantDialog = confirmable(
  ({ show, proceed }: ConfirmDialogProps<{ order_uuid?: string }, false | string>) => {
    // const { data: order } = useQueryOneOrder(order_uuid);
    const [selectedVariant, setSelectedVariant] = React.useState<string | null>(null);

    const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      variant: string
    ) => {
      setSelectedVariant(variant);
    };

    const handleClose = () => proceed(false);

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Variant</DialogTitle>
        <DialogContent>
          <List component="nav">
            <ListItemButton
              selected={selectedVariant === "quote"}
              onClick={event => handleListItemClick(event, "quote")}
            >
              <ListItemText primary="Quote" />
            </ListItemButton>
            <ListItemButton
              disabled
              selected={selectedVariant === "adjustment"}
              onClick={event => handleListItemClick(event, "adjustment")}
            >
              <ListItemText primary="Adjustment" />
            </ListItemButton>
            <ListItemButton
              disabled
              selected={selectedVariant === "rectification"}
              onClick={event => handleListItemClick(event, "rectification")}
            >
              <ListItemText primary="Rectification" />
            </ListItemButton>
            <ListItemButton
              disabled
              selected={selectedVariant === "service"}
              onClick={event => handleListItemClick(event, "service")}
            >
              <ListItemText primary="Service" />
            </ListItemButton>
            <ListItemButton
              disabled
              selected={selectedVariant === "warranty"}
              onClick={event => handleListItemClick(event, "warranty")}
            >
              <ListItemText primary="Warranty" />
            </ListItemButton>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => !!selectedVariant && proceed(selectedVariant)}
            color="primary"
            autoFocus
            disabled={!selectedVariant}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const orderNewVariantConfirmationDialog = createConfirmation(OrderNewVariantDialog);
