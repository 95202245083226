import React from "react";
import { useQueryLogEmails } from "../../api/LogEmail";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const LogEmailList = () => {
  useSetPageTitle("Log Emails");
  const columns: GridColDef[] = [
    { field: "uuid", headerName: "UUID", flex: 1 },
    { field: "message_id", headerName: "Msg ID", flex: 1 },
    { field: "template", headerName: "Template", flex: 1 },
    {
      field: "to",
      headerName: "To",
      flex: 1,
      valueGetter: (value, row) => row?.envelope?.to ?? row?.original_message?.to
    },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "status_message", headerName: "Status Msg", flex: 1 }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withSearchQueryProvider(
  withDataGridPageProvider(LogEmailList, {
    useQueryList: useQueryLogEmails
  })
);
