import { Button, ButtonGroup, Card, Typography } from "@mui/material";
import { expandedStateAtom } from "./BookedList.tsx";
import { useAtom, useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { queryTypeFilterAtom } from "../../api/OrderFitting.ts";

export const TableState = ({ totalCount }: { totalCount: number }) => {
  const [expanded, setExpanded] = useAtom(expandedStateAtom);
  const queryType = useAtomValue(queryTypeFilterAtom);

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: 4,
        alignItems: "center",
        gap: 3
      }}
    >
      {["booked", "hold"].includes(queryType) && (
        <>
          <ButtonGroup variant="outlined" size="small">
            <Button disabled={expanded === true} onClick={() => setExpanded(true)}>
              Expand
            </Button>
            <Button
              disabled={isEmpty(expanded) && expanded !== true}
              onClick={() => setExpanded({})}
            >
              Close
            </Button>
          </ButtonGroup>
          |
        </>
      )}

      <Typography variant="body2" color="text.secondary">
        Total Records: {totalCount}
      </Typography>
    </Card>
  );
};
