import React, { Suspense } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Alert as MuiAlert,
  Card,
  Divider as MuiDivider,
  Stack,
  Tabs,
  Tab,
  Typography,
  Box
} from "@mui/material";
import { spacing } from "@mui/system";
import { useQueryOneCustomer } from "../../api/Customer";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import CustomerDetailsCardV2 from "./CustomerDetailsCardV2";
import CustomerStatisticsCard from "./CustomerStatisticsCard.tsx";
import { PrintDebtorStatement } from "./PrintDebtorStatement.tsx";
import OrderListWithQuery from "./OrderListByQuery";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext.tsx";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext.tsx";
import { LeadListComponent } from "../lead/LeadList.tsx";
import qs from "qs";
import { useMutationBulkDeleteLead, useQueryLeads } from "../../api/Lead";
import Loader from "../../components/Loader.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import EventsTimelineCard from "./EventLogsCard.tsx";
import { Can } from "../../casl.ts";

const Divider = styled(MuiDivider)(spacing);
const Alert = styled(MuiAlert)(spacing);

function CustomerDetails() {
  const { uuid } = useParams<string>();
  const { data } = useQueryOneCustomer(uuid);
  useSetPageTitle("Customer");

  const [value, setValue] = React.useState(6);
  const handleTabsOnChange = (event: React.SyntheticEvent, value: number) => {
    setValue(value);
  };
  const orderQuery = {
    filter: {
      customer_id: data.id,
      is_invoiced: 0,
      order_type: "order",
      status: 3
    }
  };

  const invoicesQuery = {
    filter: {
      customer_id: data.id,
      is_invoiced: 1
    }
  };

  const serviceQuery = {
    filter: {
      customer_id: data.id,
      is_invoiced: 0,
      order_type: "service",
      status: 3
    }
  };

  const warrantyQuery = {
    filter: {
      customer_id: data.id,
      is_invoiced: 0,
      order_type: "warranty",
      status: 3
    }
  };
  const quotesQuery = {
    filter: {
      customer_id: data.id,
      is_quote_finalized: 1,
      order_type: "quote"
    }
  };

  const LeadList = withSearchQueryProvider(
    withDataGridPageProvider(LeadListComponent, {
      useQueryList: useQueryLeads,
      useMutationBulkDelete: useMutationBulkDeleteLead,
      pageTitle: "Leads",
      addButtonNavigateTo:
        "/lead/add" +
        qs.stringify(
          {
            initialValues: {
              customer_uuid: uuid
            },
            navigate_to: location.pathname + location.search
          },
          { addQueryPrefix: true }
        )
    }),
    {
      defaultSearchQuery: {
        filter_customer: {
          uuid
        }
      }
    }
  );
  return (
    <>
      <Stack direction="row" spacing={6} alignItems={"center"} useFlexGap flexWrap="wrap">
        <PageTitleWithLoading title={data?.email} />
        <Stack
          direction="row"
          spacing={2}
          divider={<span>|</span>}
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
          marginLeft={20}
          mx={2}
          pt={3}
        >
          <Typography variant="h4">{data?.customer_group?.name}</Typography>
          <Typography
            variant="h4"
            textTransform={data?.customer_type === "cod" ? "uppercase" : "capitalize"}
          >
            {data?.customer_type}
          </Typography>
        </Stack>
      </Stack>

      <Divider my={6} />

      {Boolean(data?.is_tester) && (
        <Alert severity="warning" my={4}>
          Payments related to this customer will be marked as TEST mode
        </Alert>
      )}

      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <Card>
          <CustomerDetailsCardV2 hideDetailsButton />
        </Card>
        <Card
          sx={{
            display: "grid",
            justifyContent: "space-between",
            alignItems: "self-end"
          }}
        >
          <Card>
            <Suspense fallback={<Loader />}>
              <CustomerStatisticsCard customer_uuid={data?.uuid} />
            </Suspense>
          </Card>
          <PrintDebtorStatement customer_uuid={data?.uuid} />
        </Card>
      </Stack>
      <Card
        sx={{
          mb: 3,
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Tabs value={value} onChange={handleTabsOnChange}>
          <Tab iconPosition="end" label="Leads" value={6} />
          <Tab iconPosition="end" label="Orders" value={1} />
          <Tab iconPosition="end" label="Invoice" value={2} />

          <Tab iconPosition="end" label="Service" value={3} />
          <Tab iconPosition="end" label="Warranty" value={4} />
          <Tab iconPosition="end" label="Quotes" value={5} />
        </Tabs>
      </Card>

      {value === 6 ? (
        <LeadList />
      ) : (
        <OrderListWithQuery
          query={
            value === 1
              ? orderQuery
              : value === 2
              ? invoicesQuery
              : value === 3
              ? serviceQuery
              : value === 4
              ? warrantyQuery
              : quotesQuery
          }
        />
      )}

      <Can I="Read" a="event_store">
        <Box sx={{ mt: 4 }}>
          <EventsTimelineCard stream="customers" aggregate_id={uuid} />
        </Box>
      </Can>
    </>
  );
}

export default CustomerDetails;
