import {
  useMutationSaveOrderFitting,
  useQueryOrderFittingFormOptions
} from "../../../api/OrderFitting.ts";
import { debounce } from "lodash";
import { SelectInput } from "./SelectInput.tsx";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useState } from "react";
import { v4 } from "uuid";

export const InstallConfirmationStatusInput = ({
  uuid: _uuid,
  order_uuid,
  install_confirmation_status
}: {
  order_uuid: string;
  uuid?: string;
  install_confirmation_status?: string;
}) => {
  const [value, setValue] = useState<string | undefined>(install_confirmation_status);
  const { data: formOptions } = useQueryOrderFittingFormOptions();
  const { mutateAsync: saveOrderFitting, isLoading } = useMutationSaveOrderFitting();

  const uuid = _uuid ?? v4();

  const handleOnChange = debounce(async (event: SelectChangeEvent<any>) => {
    setValue(event.target.value);
    const prev = value;
    saveOrderFitting({
      uuid,
      order_uuid,
      install_confirmation_status: event.target.value || null
    }).catch(() => setTimeout(() => setValue(prev), 500));
  }, 100);

  return (
    <SelectInput
      title="Confirmation Status"
      isLoading={isLoading}
      onChange={handleOnChange}
      value={value}
      formOptions={formOptions?.install_confirmation_statuses?.map(install_confirmation_status => [
        install_confirmation_status,
        install_confirmation_status
      ])}
    />
  );
};
