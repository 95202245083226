import React from "react";
import { Formik } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import {
  QUERY_KEY,
  useQueryOneOrderLineItem,
  useQueryOrderLineItemFormOptions
} from "../../api/OrderLineItem";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { useQueryParam } from "use-query-params";
import { StringParam } from "serialize-query-params";
import requiredHelpText from "../../utils/requiredHelpText";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  order_uuid?: string;
  product_uuid?: string;
  quantity?: number;
}

const OrderLineItemForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const [order_uuid] = useQueryParam("order_uuid", StringParam);
  const { data: formOptions } = useQueryOrderLineItemFormOptions();
  const { data } = useQueryOneOrderLineItem(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();

  const validationSchema = Yup.object().shape({
    order_uuid: Yup.string().uuid().required().label("Order"),
    product_uuid: Yup.string()
      .uuid()
      .required()
      .label("Product")
      .nullable()
      .oneOf(formOptions?.products?.map(({ uuid }: any) => uuid) ?? []),
    quantity: Yup.number().required().min(0)
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/order",
    validationSchema
  });

  return (
    <Formik
      initialValues={{
        order_uuid: order_uuid ?? data?.order?.uuid ?? null,
        product_uuid: data?.product?.uuid ?? null,
        quantity: 0,
        ...data
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        dirty,
        setValues,
        setFieldValue
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container columnSpacing={6}>
                <Grid item lg={2}>
                  <List component="nav">
                    {formOptions?.products?.map((product: any) => {
                      return (
                        <ListItemButton
                          key={product.uuid}
                          selected={values.product_uuid === product.uuid}
                          onClick={() => setFieldValue("product_uuid", product.uuid)}
                        >
                          <ListItemText primary={product.code} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item lg={10}>
                  <TextField
                    name="quantity"
                    label="Quantity"
                    value={Number(values.quantity ?? 0)}
                    error={Boolean(touched.quantity && errors.quantity)}
                    fullWidth
                    helperText={
                      (touched.quantity && errors.quantity) ||
                      requiredHelpText("quantity", validationSchema)
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => ({
                        ...prevValues,
                        product_uuid: faker.helpers.arrayElement(
                          formOptions?.products?.map(({ uuid }: any) => uuid)
                        ),
                        quantity: faker.datatype.number({ min: 1, max: 10 })
                      }));
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default OrderLineItemForm;
