import { Link, useNavigate } from "react-router-dom";
import { useFilterQueryParam } from "./useFilterQueryParam";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import {
  GridSlotProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import { Add as AddIcon } from "@mui/icons-material";
import qs from "qs";
import { Can } from "../../../casl";
import { Feature } from "use-feature";

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    totalValue: string;
  }
}

export const OrderListHeaderToolbar = ({
  setFilterButtonEl,
  totalValue
}: GridSlotProps["toolbar"] & { totalValue: string }) => {
  const [filters, setFilters] = useFilterQueryParam();
  const navigate = useNavigate();

  return (
    <Stack
      spacing={4}
      direction="row"
      margin={4}
      alignItems="baseline"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Stack
        spacing={6}
        direction="column"
        margin={4}
        alignItems="baseline"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Typography variant="h3">Orders</Typography>

        <Stack spacing={4} direction="row" margin={4} alignItems="center" flexWrap="wrap">
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="date-select-label" margin="dense" shrink>
              Date Filter
            </InputLabel>
            <Select
              labelId="date-select-label"
              id="date-select"
              value={filters.dateKey ?? ""}
              label="Date Filter"
              size="small"
              autoWidth
              onChange={e =>
                setFilters(filters => ({
                  ...filters,
                  dateKey: e.target.value
                }))
              }
            >
              <MenuItem>
                <em>None</em>
              </MenuItem>
              <MenuItem value="createdAt">Quoted date</MenuItem>
              <MenuItem value="quote_accepted_at">Accepted date</MenuItem>
              <MenuItem value="is_invoiced_at">Invoiced date</MenuItem>
            </Select>
          </FormControl>

          <DatePicker
            label="Start"
            value={filters.startDate ?? null}
            disabled={!filters.dateKey}
            onChange={value =>
              setFilters(filters => ({
                ...filters,
                startDate: value as Date
              }))
            }
            slotProps={{
              textField: {
                size: "small"
              }
            }}
          />

          <DatePicker
            label="End"
            value={filters.endDate ?? null}
            disabled={!filters.dateKey}
            onChange={value =>
              setFilters(filters => ({
                ...filters,
                endDate: endOfDay(value as Date)
              }))
            }
            slotProps={{
              textField: {
                size: "small"
              }
            }}
          />

          <IconButton
            aria-label="Reset filter"
            onClick={() => navigate(".")}
            size="small"
            disabled={!filters.dateKey}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        {!!filters.dateKey && (
          <Card variant="outlined">
            <Stack
              spacing={4}
              direction="row"
              margin={4}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <TextField
                label="Total Value"
                variant="standard"
                inputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={totalValue}
                size="small"
              />
            </Stack>
          </Card>
        )}
      </Stack>

      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <Feature name="FEATURE_RESTORE_DELETED">
          <Can I="RestoreDeletedOrder" a="order">
            <Button
              onClick={() =>
                setFilters(_filters => {
                  return {
                    ..._filters,
                    deleted: !_filters.deleted
                  };
                })
              }
              variant={filters.deleted ? "contained" : "text"}
              color="secondary"
              size="small"
            >
              <RestoreFromTrashIcon />
              Deleted
            </Button>
          </Can>
        </Feature>
        <Can I="CreateOrder" a="order">
          <Button
            component={Link}
            to={
              "/order/add/step-1" +
              qs.stringify(
                {
                  navigate_to: location.pathname + location.search
                },
                { addQueryPrefix: true }
              )
            }
            variant="text"
            color="secondary"
            size="small"
          >
            <AddIcon />
            Add
          </Button>
        </Can>
      </GridToolbarContainer>
    </Stack>
  );
};
