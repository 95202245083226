import { intersection, keys, pick } from "lodash";

export const optimisticUpdateAppend = (responseData: any) => (prev: any) => {
  if (!prev) {
    return prev;
  }

  return [...prev, responseData];
};

export const optimisticUpdateModifyLists = (uuid: string, responseData: any) => (prev: any) => {
  if (!prev) {
    return prev;
  }

  return prev?.map((row: any) => {
    if (row.uuid !== uuid) return row;

    const updates = pick(responseData, intersection(keys(row), keys(responseData)));

    return {
      ...row,
      ...updates
    };
  });
};

export const optimisticUpdateModifyRow = (responseData: any) => (prevRow: any) => {
  if (!prevRow) {
    return prevRow;
  }

  const updates = pick(responseData, intersection(keys(prevRow), keys(responseData)));

  return {
    ...prevRow,
    ...updates
  };
};

export const optimisticUpdateDeleteRows = (uuids: string[]) => (prev: any) => {
  if (!prev) {
    return prev;
  }

  return prev.filter((row: any) => !uuids.includes(row.uuid));
};
