import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import { Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

export const DataGridToolbar = () => {
  const [, setOpenAggregateSearch] = useQueryParam(
    "open_agg_search",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={3}
      m={3}
      my={4}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
      >
        <GridToolbarContainer>
          <GridToolbarQuickFilter placeholder="Agg ID/UUID" />
          <GridToolbarFilterButton />
          <hr />
          <Button
            onClick={() => setOpenAggregateSearch(true)}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<SearchIcon />}
          >
            Aggregate
          </Button>
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
};
