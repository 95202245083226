import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useQueryModelsV2,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import Uppy, { IndexedObject, UploadedUppyFile, UppyOptions } from "@uppy/core";
import axios from "../utils/axios";
import XHRUpload from "@uppy/xhr-upload";

export const QUERY_KEY = "file";

export const useQueryFiles = (query: any) => useQueryModelsV2(QUERY_KEY, query);

export const useQueryOneFile = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddFile = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationUploader = (options?: UppyOptions) => {
  const uppy = new Uppy({
    autoProceed: true,
    ...options,
    restrictions: {
      maxNumberOfFiles: 1,
      // 64 mb
      maxFileSize: 64000000,
      ...options?.restrictions
    }
  }).use(XHRUpload, {
    endpoint: "/api/file/upload",
    headers: {
      Authorization: axios.defaults.headers.common.Authorization as string
    }
  });

  return useMutationAbstract<
    UploadedUppyFile<IndexedObject<any>, IndexedObject<any>>,
    unknown,
    { file: File; meta?: any }
  >({
    useErrorBoundary: false,
    mutationFn: async data => {
      const id = uppy.addFile({
        data: data.file,
        name: data.file.name,
        type: data.file.type,
        meta: { ...data.meta }
      });
      const result = await uppy.upload();
      const uploadedFile = result.successful.find(uploaded => uploaded.id === id);
      const failedFile = result.failed.find(failed => failed.id === id);

      if (failedFile) {
        throw new Error(`File upload failed: ${failedFile.error}`);
      }

      if (!uploadedFile) {
        throw new Error("Uploaded file not found in result");
      }

      return uploadedFile;
    }
  });
};

export const useMutationDeleteFile = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteFile = () => useMutationBulkDeleteModelV2(QUERY_KEY);
