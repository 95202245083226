import React, { Suspense } from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Chip,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Link as MuiLink,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { spacing } from "@mui/system";
import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem";
import { generatePath, Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  useMutationAcceptOrder,
  useMutationOrderSetIsInvoiced,
  useMutationOrderSetIsQuoteFinalized,
  useQueryOneOrder
} from "../../api/Order";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CartSummaryTable from "./form/CartSummaryTable";
import { Helmet } from "react-helmet-async";
import useIsLoading from "../../hooks/useIsLoading";
import confirm from "../../components/Confirm";
import { format, parseISO } from "date-fns";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { filesize } from "filesize";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { OrderStatus } from "../../types/order_status";
import { AccordionOrderLineItem } from "./components/AccordionOrderLineItem";
import { OrderFinalizedPDF } from "./OrderFinalizedPDF";
import Loader from "../../components/Loader";
import { OrderFinalizedOptionsButton } from "./components/OrderFinalizedOptionsButton";
import PaymentTermsTable from "./form/PaymentTermsTable";
import CustomerDetailsCardV2 from "../customer/CustomerDetailsCardV2";
import TransactionsTable from "./form/TransactionsTable";
import { setInvoicedDateDialog } from "./components/InvoicedDateDialog";
import { Feature, useFeature } from "use-feature";
import { useQueryEvtStoreAggregate } from "../../api/EvtStore";
import { attachmentsDialog } from "./components/AttachmentsDialog.tsx";
import EventsTimelineCard from "../customer/EventLogsCard.tsx";
import { Can } from "../../casl.ts";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const OrderQuoteFinalized = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid);
  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order?.id);
  const isLoading = useIsLoading();
  const navigate = useNavigate();
  const { mutateAsync: setIsQuoteFinalized } = useMutationOrderSetIsQuoteFinalized();
  const { mutateAsync: setIsInvoiced } = useMutationOrderSetIsInvoiced();

  const [openAttachments, setOpenAttachments] = useQueryParam(
    "open_attachments",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );

  const { mutateAsync: acceptOrder } = useMutationAcceptOrder();

  const debug = useFeature("FEATURE_DEBUG_ORDER");

  const { data: orderAgg } = useQueryEvtStoreAggregate("orders", debug ? uuid : undefined);

  return (
    <Stack direction="column" spacing={3} useFlexGap>
      <Helmet title={`Order ${order?.reference_number}`} />

      {Boolean(order?.is_test_mode) && (
        <Card>
          <Alert severity="warning">
            <AlertTitle>TEST ORDER</AlertTitle>
            Payments will be marked as TEST mode.
          </Alert>
        </Card>
      )}

      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Typography variant="h3" textTransform="capitalize" gutterBottom>{`${
                  order?.order_type
                } ${order?.reference_number}  ${
                  order.quote_accepted_at && !order?.is_invoiced
                    ? "Pro Forma Invoice"
                    : order?.quote_accepted_at && order?.is_invoiced
                    ? "Tax Invoice"
                    : ""
                }`}</Typography>

                {order?.attachments &&
                  order.attachments.length > 0 && ( // Check if attachments exist
                    <IconButton
                      aria-label="Attachments"
                      onClick={async () =>
                        await attachmentsDialog({
                          title: "Internal Attachments",
                          is_internal: true,
                          order_id: order?.id,
                          order_uuid: order?.uuid
                        })
                      }
                    >
                      <AttachFileIcon />
                    </IconButton>
                  )}

                <AttachmentsDialog
                  open={openAttachments}
                  onClose={() => setOpenAttachments(false)}
                  attachments={order?.attachments}
                />

                {isLoading && <CircularProgress mx={2} size={25} />}
              </Box>

              {!!order?.notes_reference && (
                <Typography variant="h6" gutterBottom>
                  {order?.notes_reference}
                </Typography>
              )}

              {order?.sales_rep && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Typography variant="caption">
                    {`Prepared by ${order?.sales_rep?.full_name}`}
                  </Typography>
                  <Typography variant="caption">
                    {format(new Date(order?.createdAt), "dd/MM/yyyy p")}
                  </Typography>
                  {order?.sales_rep?.email && (
                    <MuiLink href={`mailto:${order?.sales_rep?.email}`} variant="caption">
                      {order?.sales_rep?.email}
                    </MuiLink>
                  )}
                  {order?.sales_rep?.phone_number && (
                    <MuiLink href={`mailto:${order?.sales_rep?.phone_number}`} variant="caption">
                      {order?.sales_rep?.phone_number}
                    </MuiLink>
                  )}
                </Stack>
              )}

              {order?.last_updated_by_user && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Typography variant="caption">
                    {`Last edited by ${order?.last_updated_by_user?.full_name}`}
                  </Typography>

                  <Typography variant="caption">
                    {format(new Date(order?.updatedAt), "dd/MM/yyyy p")}
                  </Typography>
                </Stack>
              )}

              <Feature name="FEATURE_DEBUG_ORDER">
                <Stack
                  mt={3}
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {!!order?.is_qbcc && <Chip label="QBCC" size="small" />}
                  {!!order?.is_check_measure && <Chip label="Check Measure" size="small" />}
                </Stack>
              </Feature>
            </Box>

            {order?.quote_accepted_at ? (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h4">Order Accepted</Typography>
                  <Typography variant="caption">
                    {format(new Date(order?.quote_accepted_at), "dd/MM/yyyy p")}
                  </Typography>

                  <Typography variant="caption">
                    {[
                      order?.deposit_amount_paid_at
                        ? "Deposit Processed"
                        : order?.quote_deposit_payment_initiated_at
                        ? "Deposit Pending"
                        : "",
                      !!order?.quote_deposit_payment_initiated_using &&
                        ` (${order?.quote_deposit_payment_initiated_using})`
                    ]
                      .filter(v => v)
                      .join(" ")
                      .trim()}
                  </Typography>
                </Box>
                <Button variant="contained" color="primary">
                  Check Measure
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (
                      await confirm({
                        confirmation: "You are about to mark this Accepted. Are you sure?",
                        options: {
                          title: "Confirm"
                        }
                      })
                    ) {
                      uuid && (await acceptOrder(uuid));
                    }
                  }}
                >
                  Accept Order
                </Button>
              </>
            )}

            <Stack direction="column" alignItems="start" useFlexGap flexWrap="wrap">
              <Stack
                direction="row"
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
                useFlexGap
                flexWrap="wrap"
              >
                {order?.quote_accepted_at && uuid && (
                  <Button
                    variant="outlined"
                    startIcon={order?.is_invoiced && <CheckCircleIcon color="primary" />}
                    onClick={async () => {
                      if (!order?.is_invoiced) {
                        const result = await setInvoicedDateDialog({
                          uuid
                        });

                        result &&
                          (await setIsInvoiced({
                            uuid,
                            is_invoiced: true,
                            is_invoiced_at: parseISO(result.is_invoiced_at)
                          }));
                      } else {
                        if (
                          await confirm({
                            confirmation: `You are about to mark this as not invoiced. Are you sure?`,
                            options: {
                              title: "Confirm"
                            }
                          })
                        ) {
                          await setIsInvoiced({
                            uuid,
                            is_invoiced: false,
                            is_invoiced_at: null
                          });
                        }
                      }
                    }}
                  >
                    Invoice
                  </Button>
                )}

                {!order?.quote_accepted_at && (
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      if (
                        await confirm({
                          confirmation: `You are about to enable editing of this ${order?.order_type}. It will be marked as not finalized. Are you sure?`,
                          options: {
                            title: "Confirm"
                          }
                        })
                      ) {
                        await setIsQuoteFinalized({
                          uuid,
                          is_quote_finalized: false,
                          is_quote_finalized_at: null
                        });
                        navigate(
                          generatePath("/order/:uuid/edit/step-3", {
                            uuid: uuid ?? null
                          })
                        );
                      }
                    }}
                  >
                    Edit
                  </Button>
                )}

                <OrderFinalizedOptionsButton />
              </Stack>
              {order?.is_invoiced && (
                <Typography variant="caption">
                  {format(new Date(order?.is_invoiced_at), "dd/MM/yyyy p")}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>

        <OrderFinalizedPDF order={order} />
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Suspense fallback={<Loader />}>
                <List>
                  {orderLineItems?.map((order_line_item: any) => {
                    return (
                      <AccordionOrderLineItem
                        key={order_line_item.uuid}
                        order_line_item={order_line_item}
                      />
                    );
                  })}
                </List>
              </Suspense>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={3} useFlexGap>
            <Card>
              <CustomerDetailsCardV2
                site_address={order?.site_address}
                customer_uuid={order?.customer?.uuid}
                hideMoreOptionsButton
                hideCreateOrderFromButton
              />
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Summary
                </Typography>

                <CartSummaryTable />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Payment Terms
                </Typography>

                {!!orderAgg?.payment_term_config?.name && (
                  <Link
                    component={RouterLink}
                    underline="none"
                    target="_blank"
                    to={generatePath("/payment-term-config/:uuid/edit", {
                      uuid: orderAgg?.payment_term_config?.uuid
                    })}
                  >
                    <Chip label={orderAgg?.payment_term_config?.name} size="small" sx={{ mx: 1 }} />
                  </Link>
                )}
                {!!orderAgg?.payment_term_config?.attach_qbcc_contract && (
                  <Chip label="Attach QBCC" size="small" sx={{ mx: 1 }} />
                )}

                <PaymentTermsTable payment_term_config={orderAgg?.payment_term_config} />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <TransactionsTable disablePaymentButton={!order?.quote_accepted_at} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Card>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>Order Progress:</Typography>
          <Stepper connector={<ChevronRightIcon />} sx={{ justifyContent: "center" }}>
            <Step completed={OrderStatus.OPEN === (order?.status & OrderStatus.OPEN)}>
              <StepLabel>Quote</StepLabel>
            </Step>
            <Step completed={OrderStatus.PROCESSING === (order?.status & OrderStatus.PROCESSING)}>
              <StepLabel>Processing</StepLabel>
            </Step>
            <Step
              completed={
                OrderStatus.CHECK_MEASURE_PENDING ===
                (order?.status & OrderStatus.CHECK_MEASURE_PENDING)
              }
            >
              <StepLabel>Check Measure Pending</StepLabel>
            </Step>
            <Step completed={OrderStatus.MANUFACTURE === (order?.status & OrderStatus.MANUFACTURE)}>
              <StepLabel>Manufacture</StepLabel>
            </Step>
            <Step completed={OrderStatus.READY === (order?.status & OrderStatus.READY)}>
              <StepLabel>Ready</StepLabel>
            </Step>
            <Step last completed={OrderStatus.COMPLETE === (order?.status & OrderStatus.COMPLETE)}>
              <StepLabel>Complete</StepLabel>
            </Step>
          </Stepper>
        </CardContent>
      </Card>

      {debug && (
        <Can I="Read" a="event_store">
          <EventsTimelineCard stream="orders" aggregate_id={uuid} />
        </Can>
      )}
    </Stack>
  );
};

export default OrderQuoteFinalized;

const AttachmentsDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  attachments?: any[];
}> = props => {
  const { onClose, open, attachments } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Attachments</DialogTitle>
      <List sx={{ pt: 0 }}>
        {!attachments?.length && (
          <ListItem>
            <ListItemText primary={"No attachments"} />
          </ListItem>
        )}

        {attachments?.map(attached => (
          <ListItem key={attached.id}>
            <ListItemButton component="a" target="_blank" href={attached.location_url}>
              <ListItemAvatar>
                <Avatar>
                  <AttachFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={attached.name}
                secondary={filesize(attached.size_in_bytes, {
                  standard: "jedec"
                })}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
