import React from "react";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import confirm from "../../components/Confirm";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import {
  useMutationBulkDeletePaymentTermConfig,
  useMutationDeletePaymentTermConfig,
  useQueryPaymentTermConfigs
} from "../../api/PaymentTermConfig";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useHealthChecker from "../../hooks/useHealthChecker";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const PaymentTermConfigList = () => {
  useSetPageTitle("Payment Terms");
  const { mutateAsync: deleteItem } = useMutationDeletePaymentTermConfig();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    // {
    //   field: "expression",
    //   headerName: "Expression",
    //   flex: 1,
    //   valueGetter: (value, row) => {
    //     return row?.cel_expression;
    //   }
    // },
    {
      field: "deposit",
      headerName: "Deposit %",
      flex: 1,
      valueGetter: (value, row) => {
        return row?.config?.deposit;
      }
    },
    {
      field: "pre-delivery",
      headerName: "Pre-Delivery %",
      flex: 1,
      valueGetter: (value, row) => {
        return row?.config?.["pre-delivery"];
      }
    },
    {
      field: "final",
      headerName: "Final %",
      flex: 1,
      valueGetter: (value, row) => {
        return row?.config?.final;
      }
    },
    {
      field: "attach_qbcc_contract",
      headerName: "Attach QBCC Contract",
      flex: 1,
      valueGetter: (value, row) => {
        return row?.attach_qbcc_contract ? "Yes" : "No";
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { uuid } = row;

        return [
          <Tooltip title="Edit" key={`edit-${uuid}`}>
            <IconButton
              size="large"
              component={RouterLink}
              to={generatePath("/payment-term-config/:uuid/edit", { uuid })}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete" key={`delete-${uuid}`}>
            <IconButton
              size="large"
              onClick={async () => {
                if (
                  await confirm({
                    confirmation: "You are about to delete. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  })
                ) {
                  await deleteItem(uuid);
                }
              }}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ];
      },
      flex: 1
    }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withSearchQueryProvider(
  withDataGridPageProvider(PaymentTermConfigList, {
    useQueryList: useQueryPaymentTermConfigs,
    useMutationBulkDelete: useMutationBulkDeletePaymentTermConfig,
    addButtonNavigateTo: "/payment-term-config/add"
  })
);
