import OrderLineItemAdd from "./OrderLineItemAdd";
import OrderLineItemEdit from "./OrderLineItemEdit";

const routes = [
  {
    path: "/order-line-item/add",
    element: <OrderLineItemAdd />
  },
  {
    path: "/order-line-item/:uuid/edit",
    element: <OrderLineItemEdit />
  }
];

export default routes;
