import * as Yup from "yup";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import {
  dateRangeFilterAtom,
  departmentsFilterAtom,
  fitterUsersFilterAtom,
  isPowderCoatFilterAtom,
  jobStatusesFilterAtom,
  jobTypesFilterAtom,
  queryTypeFilterAtom,
  useQueryOrderFittingFormOptions,
  withTAsFilterAtom
} from "../../api/OrderFitting";
import { useAtom, useAtomValue } from "jotai";
import {
  Badge,
  Button,
  Card as MuiCard,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { format } from "date-fns";
import styled from "@emotion/styled";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

interface CardProps {
  editing?: string;
}

const Card = styled(MuiCard)<CardProps>`
  &:hover {
    background-color: ${props => (props.editing ? "#FFF" : "rgba(172, 172, 172, 0.08)")};
    cursor: ${props => (props.editing ? "auto" : "pointer")};
  }
`;

export const Filter = () => {
  const { data: formOptions } = useQueryOrderFittingFormOptions();

  const [dateRangeFilter, setDateRangeFilter] = useAtom(dateRangeFilterAtom);
  const [fitterUserUUIDs, setFitterUserUUIDs] = useAtom(fitterUsersFilterAtom);
  const [jobTypes, setJobTypes] = useAtom(jobTypesFilterAtom);
  const [jobStatus, setJobStatus] = useAtom(jobStatusesFilterAtom);
  const [withTAs, setWithTAs] = useAtom(withTAsFilterAtom);
  const [departments, setDepartments] = useAtom(departmentsFilterAtom);
  const [isPowdercoat, setIsPowdercoat] = useAtom(isPowderCoatFilterAtom);
  const queryTypeFilter = useAtomValue(queryTypeFilterAtom);
  const [editing, setEditing] = useState(false);

  const validationSchema = Yup.object<any>().shape({
    start: Yup.date().required().label("Start"),
    end: Yup.date().required().label("End"),
    fitter_user_uuids: Yup.array().of(
      Yup.string()
        .required()
        .oneOf([...(formOptions?.fitter_users?.map(({ uuid }: any) => uuid) ?? []), "without"])
    ),
    job_types: Yup.array().of(
      Yup.string()
        .required()
        .oneOf([...(formOptions?.job_types ?? []), "without"])
    ),
    job_status: Yup.array().of(
      Yup.string()
        .required()
        .oneOf([...(formOptions?.job_statuses ?? []), "without"])
    ),
    departments: Yup.array().of(
      Yup.string()
        .required()
        .oneOf([...(formOptions?.product_lines?.map(({ uuid }: any) => uuid) ?? []), "without"])
    ),
    with_ta: Yup.boolean(),
    is_powdercoat: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      start: dateRangeFilter.start,
      end: dateRangeFilter.end,
      fitter_user_uuids: fitterUserUUIDs,
      job_types: jobTypes,
      job_status: jobStatus,
      departments,
      with_ta: withTAs,
      is_powdercoat: isPowdercoat
    },
    validationSchema,
    onSubmit: (values, helpers) => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });

      setDateRangeFilter({
        start: validated.start,
        end: validated.end
      });

      setFitterUserUUIDs(validated.fitter_user_uuids);
      setJobTypes(validated.job_types);
      setJobStatus(validated.job_status);
      setDepartments(validated.departments);
      setWithTAs(validated.with_ta);
      setIsPowdercoat(validated.is_powdercoat);

      helpers.resetForm({
        values
      });

      setEditing(false);
    }
  });

  const { handleSubmit, dirty, values, handleBlur, handleChange } = formik;

  const handleOnClick = () => {
    setEditing(true);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!dirty) {
          setEditing(false);
        }
      }}
      mouseEvent="onMouseUp"
    >
      <Card
        editing={editing ? editing.toString() : undefined}
        sx={{ display: "flex", justifyContent: "space-between", padding: 3 }}
        onClick={handleOnClick}
      >
        <Stack gap={2} direction="row" alignItems="center" flexWrap="wrap" useFlexGap>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <Stack gap={2} direction="row" alignItems="center" flexWrap="wrap" useFlexGap>
                {editing ? <FilterAltOutlinedIcon /> : <FilterAltIcon />}

                {queryTypeFilter !== "hold" && (
                  <>
                    <Field name="start" value={values.start}>
                      {({ field, form }: FieldProps) => {
                        if (!editing) {
                          return (
                            <Chip
                              label={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                              size="small"
                            />
                          );
                        }

                        return (
                          <DatePicker
                            label="Job Booked Date - Start"
                            disabled={queryTypeFilter === "pending"}
                            value={field.value}
                            onChange={value => form.setFieldValue(field.name, value || null)}
                            slotProps={{
                              textField: {
                                size: "small"
                              }
                            }}
                            sx={{ m: 1, width: 140 }}
                          />
                        );
                      }}
                    </Field>

                    <Field name="end" value={values.end}>
                      {({ field, form }: FieldProps) => {
                        if (!editing) {
                          return (
                            <Chip
                              label={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                              size="small"
                            />
                          );
                        }

                        return (
                          <DatePicker
                            disabled={queryTypeFilter === "pending"}
                            label="Job Booked Date - End"
                            value={field.value}
                            onChange={value => form.setFieldValue(field.name, value || null)}
                            minDate={values.start}
                            slotProps={{
                              textField: {
                                size: "small"
                              }
                            }}
                            sx={{ m: 1, width: 140 }}
                          />
                        );
                      }}
                    </Field>
                  </>
                )}

                <Field name="fitter_user_uuids" value={values.fitter_user_uuids}>
                  {({ field, form }: FieldProps) => {
                    if (!editing) {
                      if (!field.value) {
                        return null;
                      }

                      const hasWithout = field.value?.includes("without");

                      return (
                        <Badge badgeContent={field.value?.length} color="primary">
                          <Chip
                            sx={{ maxWidth: 100 }}
                            label={[
                              ...(formOptions?.fitter_users
                                ?.filter((user: any) => {
                                  return field.value?.includes(user.uuid);
                                })
                                ?.map((user: any) => {
                                  return user.full_name;
                                }) ?? []),
                              hasWithout && "Without fitters"
                            ]?.join(", ")}
                            size="small"
                          />
                        </Badge>
                      );
                    }

                    return (
                      <FormControl sx={{ m: 1, width: 100 }}>
                        <InputLabel id="fitters-select-label" margin="dense" shrink>
                          Fitters
                        </InputLabel>
                        <Select
                          labelId="fitters-select-label"
                          id="fitters-select"
                          label="Fitters"
                          size="small"
                          autoWidth
                          multiple
                          value={field.value || []}
                          onChange={({ target }) => {
                            const clearSelection = target.value?.includes(undefined);

                            form.setFieldValue(
                              field.name,
                              clearSelection ? undefined : target.value
                            );
                          }}
                        >
                          <MenuItem value={undefined}>
                            <ListItemIcon>
                              <CloseIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Clear</ListItemText>
                          </MenuItem>

                          <MenuItem divider value="without">
                            Empty
                          </MenuItem>

                          {formOptions?.fitter_users?.map((user: any) => (
                            <MenuItem key={user.uuid} value={user.uuid}>
                              {user.full_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="job_types" value={values.job_types}>
                  {({ field, form }: FieldProps) => {
                    if (!editing) {
                      if (!field.value) {
                        return null;
                      }

                      return (
                        <Badge badgeContent={field.value?.length} color="primary">
                          <Chip
                            sx={{ maxWidth: 100 }}
                            label={field.value
                              ?.map((value: string) =>
                                value === "without" ? "Without Job Type" : value
                              )
                              ?.join(", ")}
                            size="small"
                          />
                        </Badge>
                      );
                    }

                    return (
                      <FormControl sx={{ m: 1, width: 100 }}>
                        <InputLabel id="job-types-select-label" margin="dense" shrink>
                          Job Types
                        </InputLabel>
                        <Select
                          labelId="job-types-select-label"
                          id="job-types-select"
                          label="Job Types"
                          size="small"
                          autoWidth
                          multiple
                          value={field.value || []}
                          onChange={({ target }) => {
                            const clearSelection = target.value?.includes(undefined);

                            form.setFieldValue(
                              field.name,
                              clearSelection ? undefined : target.value
                            );
                          }}
                        >
                          <MenuItem value={undefined}>
                            <ListItemIcon>
                              <CloseIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Clear</ListItemText>
                          </MenuItem>

                          <MenuItem divider value="without">
                            Empty
                          </MenuItem>

                          {formOptions?.job_types?.map((job_type: any) => (
                            <MenuItem key={job_type} value={job_type}>
                              {job_type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="job_status" value={values.job_status}>
                  {({ field, form }: FieldProps) => {
                    if (!editing) {
                      if (!field.value) {
                        return null;
                      }

                      return (
                        <Badge badgeContent={field.value?.length} color="primary">
                          <Chip
                            sx={{ maxWidth: 100 }}
                            label={field.value
                              ?.map((value: string) =>
                                value === "without" ? "Without Job Status" : value
                              )
                              ?.join(", ")}
                            size="small"
                          />
                        </Badge>
                      );
                    }

                    return (
                      <FormControl sx={{ m: 1, width: 100 }}>
                        <InputLabel id="job-status-select-label" margin="dense" shrink>
                          Job Status
                        </InputLabel>
                        <Select
                          labelId="job-status-select-label"
                          id="job-status-select"
                          label="Job Status"
                          size="small"
                          autoWidth
                          multiple
                          value={field.value || []}
                          onChange={({ target }) => {
                            const clearSelection = target.value?.includes(undefined);

                            form.setFieldValue(
                              field.name,
                              clearSelection ? undefined : target.value
                            );
                          }}
                        >
                          <MenuItem value={undefined}>
                            <ListItemIcon>
                              <CloseIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Clear</ListItemText>
                          </MenuItem>

                          <MenuItem divider value="without">
                            Empty
                          </MenuItem>

                          {formOptions?.job_statuses?.map((item: any) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>

                {editing ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(values.with_ta)}
                        name="with_ta"
                        value={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label="With TAs"
                  />
                ) : (
                  values.with_ta && <Chip label={"With TA"} size="small" />
                )}

                {editing ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(values.is_powdercoat)}
                        name="is_powdercoat"
                        value={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label="Powdercoat"
                  />
                ) : (
                  values.is_powdercoat && <Chip label={"Powdercoat"} size="small" />
                )}

                {editing && (
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!dirty}
                    size="small"
                  >
                    Filter
                  </Button>
                )}
              </Stack>
            </form>
          </FormikProvider>
        </Stack>
      </Card>
    </ClickAwayListener>
  );
};
