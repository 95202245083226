import { UseQueryOptions } from "@tanstack/react-query";
import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useMutationPatchModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const useQueryLeads = () => useQueryModels("lead");

export const useQueryOneLead = (uuid?: string, options?: UseQueryOptions<any>) =>
  useQueryOneModel<any>("lead", uuid, options);

export const useQueryLeadFormOptions = () =>
  useQueryFormOptionsModel<{
    customers: any;
    lead_source: any;
    lead_status: any;
    marketing: any;
    sales_rep_user: any;
    next_available_reference_number: number;
  }>("lead");

export const useMutationAddLead = (uuid: string) => useMutationAddModel("lead", uuid);

export const useMutationEditLead = (uuid: string) => useMutationEditModel("lead", uuid);

export const useMutationPatchLead = (uuid: string) => useMutationPatchModel("lead", uuid);

export const useMutationDeleteLead = () => useMutationDeleteModel("lead");

export const useMutationBulkDeleteLead = () => useMutationBulkDeleteModelV2("lead");
