import React from "react";
import { Field, FieldProps, Formik } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField as MuiTextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import {
  QUERY_KEY,
  useQueryOnePaymentTermConfig,
  useQueryPaymentTermConfigFormOptions
} from "../../api/PaymentTermConfig";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import {
  defaultOperators,
  Field as QBField,
  formatQuery,
  QueryBuilder,
  RuleGroupType,
  ValueSelector
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  name?: string;
  query?: RuleGroupType; // https://react-querybuilder.js.org/api/interfaces/react_querybuilder.RuleGroupTypeIC
  cel_expression?: string;
  config?: {
    deposit: number;
    "pre-delivery": number;
    final: number;
  };
  attach_qbcc_contract?: boolean;
}

const defaultQuery: RuleGroupType = {
  combinator: "and",
  rules: [
    {
      field: "customer_types",
      operator: "in",
      valueSource: "value",
      value: ""
    },
    {
      field: "order_types",
      operator: "in",
      valueSource: "value",
      value: ""
    },
    {
      field: "customer_group_uuids",
      operator: "in",
      valueSource: "value",
      value: ""
    },
    {
      field: "is_qbcc",
      operator: "in",
      valueSource: "value",
      value: ""
    },
    {
      field: "sold_price",
      operator: "in",
      valueSource: "value",
      value: ""
    }
  ]
};

const PaymentTermConfigForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const { data: paymentTermConfig } = useQueryOnePaymentTermConfig(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();
  const { data: formOptionsData } = useQueryPaymentTermConfigFormOptions();

  const fields: QBField[] = [
    {
      name: "customer_types",
      label: "Customer Type",
      valueEditorType: "multiselect",
      values: formOptionsData?.customer_types?.map(type => ({
        name: type,
        label: type
      })),
      operators: defaultOperators.filter(op => op.name === "in")
    },
    {
      name: "order_types",
      label: "Order Type",
      valueEditorType: "multiselect",
      values: formOptionsData?.order_types?.map(type => ({
        name: type,
        label: type
      })),
      operators: defaultOperators.filter(op => op.name === "in")
    },
    {
      name: "customer_group_uuids",
      label: "Customer Group",
      valueEditorType: "multiselect",
      values: formOptionsData?.customer_groups?.map((customerGroup: any) => ({
        name: customerGroup.uuid,
        label: customerGroup.name
      })),
      operators: defaultOperators.filter(op => op.name === "in")
    },
    {
      name: "is_qbcc",
      label: "Is QBCC",
      valueEditorType: "multiselect",
      values: [
        {
          name: 1,
          label: "Is QBCC"
        },
        {
          name: 0,
          label: "NOT Is QBCC"
        }
      ],
      operators: defaultOperators.filter(op => op.name === "in")
    },
    {
      name: "sold_price",
      label: "Order Value",
      inputType: "number",
      operators: defaultOperators.filter(op => [">", ">=", "=", "<", "<="].includes(op.name))
    }
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(255),
    query: Yup.mixed(),
    config: Yup.mixed(),
    cel_expression: Yup.string().defined(),
    attach_qbcc_contract: Yup.boolean().defined().nullable()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/payment-term-config",
    validationSchema
  });

  return (
    <Formik<Values>
      initialValues={{
        name: "",
        query: defaultQuery,
        cel_expression: formatQuery(defaultQuery, { format: "cel", parseNumbers: true }),
        attach_qbcc_contract: false,
        ...paymentTermConfig
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        dirty,
        setFieldValue
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} columnSpacing={3} style={{ width: "100%" }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Name"
                    value={values.name ?? ""}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <QueryBuilder
                    fields={fields}
                    query={values.query}
                    disabled={isLoading}
                    onQueryChange={(updatedQuery: RuleGroupType) => {
                      setFieldValue("query", updatedQuery);
                      setFieldValue(
                        "cel_expression",
                        formatQuery(updatedQuery, { format: "cel", parseNumbers: true })
                      );
                    }}
                    controlElements={{
                      // addGroupAction: () => null,
                      // addRuleAction: () => null,
                      // removeRuleAction: () => null,
                      actionElement: () => null,
                      fieldSelector: props => <ValueSelector {...props} disabled />
                    }}
                  />
                </Grid>

                {/*<Grid item xs={12} sm={6}>*/}
                {/*  <Card mb={6}>*/}
                {/*    <CardContent>{values.cel_expression}</CardContent>*/}
                {/*  </Card>*/}
                {/*</Grid>*/}

                <Field name="config">
                  {({ field, form }: FieldProps) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="deposit"
                            label="Deposit %"
                            value={field.value?.deposit ?? ""}
                            fullWidth
                            // error={Boolean(touched.name && errors.name)}
                            // helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={e => {
                              form.setFieldValue("config", {
                                ...(values?.config ?? {}),
                                deposit: Number(e.currentTarget.value)
                              });
                            }}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            disabled={isLoading}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="pre-delivery"
                            label="Pre-Delivery %"
                            value={field.value?.["pre-delivery"] ?? ""}
                            fullWidth
                            // error={Boolean(touched.name && errors.name)}
                            // helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={e => {
                              form.setFieldValue("config", {
                                ...(values?.config ?? {}),
                                "pre-delivery": Number(e.currentTarget.value)
                              });
                            }}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            disabled={isLoading}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="final"
                            label="Final %"
                            value={field.value?.final ?? ""}
                            fullWidth
                            // error={Boolean(touched.name && errors.name)}
                            // helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={e => {
                              form.setFieldValue("config", {
                                ...(values?.config ?? {}),
                                final: Number(e.currentTarget.value)
                              });
                            }}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            disabled={isLoading}
                          />
                        </Grid>
                      </>
                    );
                  }}
                </Field>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.attach_qbcc_contract ?? false}
                        value={true}
                        name="attach_qbcc_contract"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label="Attach QBCC Contract?"
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                {/*{import.meta.env.DEV && (*/}
                {/*  <Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    mt={3}*/}
                {/*    onClick={async () => {*/}
                {/*      const { faker } = await import("@faker-js/faker");*/}

                {/*      setValues(prevValues => ({*/}
                {/*        ...prevValues,*/}
                {/*        name: faker.random.words(2)*/}
                {/*      }));*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <RefreshIcon /> Seed Data*/}
                {/*  </Button>*/}
                {/*)}*/}
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default PaymentTermConfigForm;
