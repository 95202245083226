import { Suspense, memo, useState } from "react";
import { ClickAwayListener } from "@mui/material";

export const EditableCell = memo(function EditableCell({ info, children }: any) {
  const value = info.getValue();
  // console.log("EditableCell rerender");
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing) {
    return (
      <span style={{ cursor: "pointer" }} onClick={() => setIsEditing(true)}>
        {(value && String(value).trim()) || <em style={{ color: "grey" }}>None</em>}
      </span>
    );
  }

  return (
    <Suspense fallback={<em style={{ color: "grey" }}>Loading...</em>}>
      <ClickAwayListener onClickAway={() => setIsEditing(false)}>
        <div>{children}</div>
      </ClickAwayListener>
    </Suspense>
  );
});

EditableCell.whyDidYouRender = false;
