import React, { useMemo } from "react";
import { generatePath, Link as RouterLink, useNavigate } from "react-router-dom";
import { Link, Menu, MenuItem } from "@mui/material";
import {
  useMutationBulkDeleteOrder,
  useMutationCopyOrder,
  useMutationDeleteOrder,
  useMutationOrderSetIsActive,
  useMutationRestoreDeletedOrder,
  useQueryOrders,
  useQueryOrdersV2
} from "../../api/Order.ts";
import confirm from "../../components/Confirm.tsx";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext.tsx";
import { DataGridPageProvider } from "../../contexts/DataGridPageContext.tsx";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage.tsx";
import calculateOrderStatusName from "../../utils/calculateOrderStatusName.ts";
import { add, Dinero, toDecimal } from "dinero.js";
import {
  dineroAUD,
  dineroAUDFromFloat,
  toDecimalFromFloat
} from "aldrin-erp-main-shared-functions";
import { useAuthAbility } from "../../hooks/useAuthAbility.ts";
import { format } from "date-fns";
import useHealthChecker from "../../hooks/useHealthChecker.ts";
import useAuth from "../../hooks/useAuth.ts";
import { USER_ROLES } from "../../constants.ts";
import { MoreVertical } from "react-feather";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Can } from "../../casl.ts";
import { useFilterQueryParam } from "../order/components/useFilterQueryParam.tsx";
import { orderCopyConfirmationDialog } from "../order/OrderCopyDialog.tsx";
import { OrderListHeaderToolbar } from "../order/components/OrderListHeaderToolbar.tsx";

const OrderListWithQuery: React.VFC<{ query: any }> = ({ query }) => {
  const { mutateAsync: deleteItem } = useMutationDeleteOrder();
  const { mutateAsync: restoreDeletedOrder } = useMutationRestoreDeletedOrder();
  const { mutateAsync: setIsActiveOrder } = useMutationOrderSetIsActive();
  const { mutateAsync: copyOrder } = useMutationCopyOrder();
  const navigate = useNavigate();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();
  const [filters] = useFilterQueryParam();

  const { data: orders } = useQueryOrdersV2(query);

  const totalValue = useMemo(
    () =>
      toDecimal(
        orders?.reduce((sum: Dinero<number>, order: any) => {
          return add(sum, dineroAUDFromFloat(order.sold_price || 0));
        }, dineroAUD(0)) ?? dineroAUD(0)
      ),
    [orders]
  );

  const dateFilterHeaderNames: Record<string, string> = {
    createdAt: "Quoted date",
    quote_accepted_at: "Accepted date",
    is_invoiced_at: "Invoiced date"
  };

  const columns: GridColDef[] = [
    {
      field: "reference_number",
      headerName: "Order #",
      width: 100,
      valueGetter: (value, row) => row.reference_number,
      renderCell: params => {
        const { value, row } = params;
        return (
          <Link
            component={RouterLink}
            underline="hover"
            to={generatePath(
              row?.is_quote_finalized ? "/order/:uuid/finalized-quote" : "/order/:uuid/edit",
              {
                uuid: row.uuid
              }
            )}
          >
            {value}
          </Link>
        );
      }
    },
    {
      flex: 1,
      minWidth: 100,
      field: filters.dateKey ?? "createdAt",
      headerName: dateFilterHeaderNames[filters.dateKey ?? "createdAt"],
      valueGetter: (value, row) => {
        return format(new Date(row[filters.dateKey ?? "createdAt"]), "dd/MM/yyyy pp");
      }
    },

    {
      field: "customerEmail",
      headerName: "Customer Email",
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        const {
          value: customerEmail,
          row: { customer }
        } = params;

        if (ability.cannot("UpdateCustomer", "customers")) {
          return customerEmail;
        }

        if (customer) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/edit", {
                uuid: customer?.uuid
              })}
            >
              {customerEmail}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.customer?.email
    },

    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      minWidth: 100,
      renderCell: params => {
        const {
          value: customerName,
          row: { customer }
        } = params;

        if (ability.cannot("UpdateCustomer", "customers")) {
          return customerName;
        }

        if (customer) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/edit", {
                uuid: customer?.uuid
              })}
            >
              {customerName}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.customer?.full_name
    },

    {
      field: "lead",
      headerName: "Lead",

      renderCell: params => {
        const {
          value: leadNumber,
          row: { lead }
        } = params;

        if (ability.cannot("UpdateLead", "lead")) {
          return leadNumber;
        }

        if (lead) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/lead/:uuid/edit", {
                uuid: lead?.uuid
              })}
            >
              {leadNumber}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.lead?.lead_number?.toString()
    },
    {
      field: "order_status",
      headerName: "Status",

      valueGetter: (value, row) => calculateOrderStatusName(row?.status)
    },
    {
      field: "order_type",
      headerName: "Type"
    },
    {
      field: "sales_rep",
      headerName: "Sales Rep",

      renderCell: params => {
        const {
          value: salesRepEmail,
          row: { sales_rep }
        } = params;

        if (ability.cannot("UpdateUser", "users")) {
          return salesRepEmail;
        }

        if (sales_rep) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/users/:uuid/edit", {
                uuid: sales_rep?.uuid
              })}
            >
              {salesRepEmail}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.sales_rep?.email
    },

    {
      field: "notes_reference",
      headerName: "Reference"
    },
    {
      field: "sold_price",
      headerName: "Amount",
      width: 100,
      valueGetter: (value, row) => {
        const { sold_price } = row;
        return toDecimalFromFloat(sold_price ?? 0);
      }
    },

    {
      field: "discount",
      headerName: "Discount",
      width: 100,
      valueGetter: (value, row) => {
        const { discount } = row;
        return `${discount ?? 0}%`;
      }
    },

    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      width: 100,

      renderCell: ({ row }) => {
        const { uuid, id } = row;
        return (
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <>
                <MoreVertical
                  fontSize="small"
                  style={{ color: "gray", cursor: "pointer" }}
                  {...bindTrigger(popupState)}
                />
                <Menu {...bindMenu(popupState)}>
                  {filters?.deleted ? (
                    <>
                      {row.deletedAt ? (
                        <Can I="RestoreDeletedOrder" a="order" key="RestoreDeletedOrder">
                          <MenuItem
                            onClick={async () => {
                              if (
                                await confirm({
                                  confirmation: "You are about to restore. Are you sure?",
                                  options: {
                                    title: "Confirm"
                                  }
                                })
                              ) {
                                await restoreDeletedOrder(uuid);
                              }
                            }}
                          >
                            Restore
                          </MenuItem>
                        </Can>
                      ) : (
                        <Can I="SetIsActive" a="order" key="SetIsActive">
                          <MenuItem
                            onClick={async () => {
                              if (
                                await confirm({
                                  confirmation: "You are about to Unarchive. Are you sure?",
                                  options: {
                                    title: "Confirm"
                                  }
                                })
                              ) {
                                await setIsActiveOrder({
                                  uuid,
                                  active: true
                                });
                              }
                            }}
                          >
                            {" "}
                            Unarchive
                          </MenuItem>
                        </Can>
                      )}
                    </>
                  ) : (
                    <>
                      {row?.is_quote_finalized && (
                        <Can I="SetIsActive" a="order" key={`set-is-active-${uuid}`}>
                          <MenuItem
                            onClick={async () => {
                              if (
                                await confirm({
                                  confirmation: "You are about to archive. Are you sure?",
                                  options: {
                                    title: "Confirm"
                                  }
                                })
                              ) {
                                await setIsActiveOrder({
                                  uuid,
                                  active: null
                                });
                              }
                            }}
                          >
                            Archive
                          </MenuItem>
                        </Can>
                      )}

                      <Can I="CopyOrder" a="order" key={`copy-${uuid}`}>
                        <MenuItem
                          onClick={async () => {
                            const order_line_item_uuids = await orderCopyConfirmationDialog({
                              order_id: id
                            });

                            if (order_line_item_uuids === false) {
                              return;
                            }

                            const response = await copyOrder({
                              uuid,
                              order_line_item_uuids,
                              order_type: "quote"
                            });
                            navigate(
                              generatePath("/order/:uuid/edit", {
                                uuid: response.copied_uuid
                              })
                            );
                          }}
                        >
                          {" "}
                          Create new copy
                        </MenuItem>
                      </Can>

                      {!row?.is_quote_finalized && (
                        <Can I="UpdateOrder" a="order" key={`edit-${uuid}`}>
                          <MenuItem
                            onClick={async () => {
                              navigate(generatePath("/order/:uuid/edit", { uuid }));
                            }}
                          >
                            Edit
                          </MenuItem>
                        </Can>
                      )}

                      <Can I="DeleteOrder" a="order" key={`delete-${uuid}`}>
                        <MenuItem
                          onClick={async () => {
                            if (
                              await confirm({
                                confirmation: "You are about to delete. Are you sure?",
                                options: {
                                  title: "Confirm"
                                }
                              })
                            ) {
                              await deleteItem(uuid);
                            }
                          }}
                          disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
                        >
                          Delete
                        </MenuItem>{" "}
                      </Can>
                    </>
                  )}
                </Menu>
              </>
            )}
          </PopupState>
        );
      }
    }
  ];

  const ability = useAuthAbility();

  return (
    <DataGridPageProvider
      useQueryList={useQueryOrders}
      useMutationBulkDelete={
        ability.can("DeleteOrder", "order") ? useMutationBulkDeleteOrder : undefined
      }
      pageTitle="Order"
      addButtonNavigateTo={ability.can("CreateOrder", "order") ? "/order/add/step-1" : undefined}
      initialState={{
        sorting: [{ field: "reference_number", sort: "desc" }]
      }}
    >
      <DataGridPage
        dataGridProps={{
          checkboxSelection: false,
          rows: orders ?? [],
          columns,
          slots: {
            toolbar: OrderListHeaderToolbar
          },
          slotProps: {
            toolbar: {
              totalValue
            }
          }
        }}
      />
    </DataGridPageProvider>
  );
};

export const OrderListWithQueryComponent = OrderListWithQuery;

export default withSearchQueryProvider(OrderListWithQuery);
