import styled from "@emotion/styled";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
  CardContent,
  Divider as MuiDivider,
  IconButton,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams
} from "react-router-dom";
import {
  useMutationDeleteLead,
  useMutationPatchLead,
  useQueryLeadFormOptions,
  useQueryOneLead
} from "../../api/Lead";
import useMenuIcon from "../../contexts/useMenuIcon";
import { FileText, Home, MoreVertical } from "react-feather";
import qs from "qs";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  Numbers as NumbersIcon
} from "@mui/icons-material";
import confirm from "../../components/Confirm";
import React from "react";
import { v4 } from "uuid";
import { useAuthAbility } from "../../hooks/useAuthAbility";
import { Can } from "../../casl";
import { useMutationAddOrderFromLead } from "../../api/Order";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)<SpacingProps>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const AboutIcon = styled.span`
  display: flex;
  svg {
    width: 14px;
    height: 14px;
  }
`;

function LeadDetailsCard({
  lead_uuid,
  deleteLeadNavigateTo
}: {
  lead_uuid?: string;
  deleteLeadNavigateTo?: string;
}) {
  const { uuid: _uuid } = useParams();
  const uuid = lead_uuid ?? _uuid;
  const { data } = useQueryOneLead(uuid);
  const { mutateAsync: deleteLead } = useMutationDeleteLead();
  const navigate = useNavigate();
  const ctx = useOutletContext<any>();
  const location = useLocation();
  const { openMenu, anchorElMenu, handleClickMenu, handleCloseMenu } = useMenuIcon();

  const { mutateAsync: addOrderFromLead } = useMutationAddOrderFromLead(v4());

  const { data: formOptionsData } = useQueryLeadFormOptions();
  const { mutateAsync: patchLead, isLoading: isLoadingPatchLead } = useMutationPatchLead(
    uuid ?? ""
  );

  const ability = useAuthAbility();

  const handlePatchLead = (property: string) => async (e: SelectChangeEvent<any>) => {
    await patchLead({
      [property]: e.target.value
    });
  };

  const {
    lead_number,
    lead_source,
    lead_status,
    marketings,
    notes,
    sales_rep_user,
    site_address,
    customer
  } = data ?? {};

  return (
    <Card>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Lead</Typography>

          <>
            <IconButton onClick={handleClickMenu} size="small" disableFocusRipple>
              <MoreVertical />
            </IconButton>
            <Menu anchorEl={anchorElMenu} open={openMenu} onClose={handleCloseMenu}>
              <MenuItem
                disabled={ability.cannot("UpdateLead", "lead")}
                onClick={() => {
                  handleCloseMenu();
                  navigate(
                    ctx?.editLeadBtnUrl ??
                      generatePath(
                        "/lead/:uuid/edit?" +
                          qs.stringify({
                            navigate_to: location.pathname + location.search
                          }),
                        { uuid }
                      )
                  );
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Edit
              </MenuItem>
              <MenuItem
                disabled={ability.cannot("DeleteLead", "lead")}
                onClick={async () => {
                  handleCloseMenu();
                  if (
                    await confirm({
                      confirmation: "You are about to delete Lead. Are you sure?",
                      options: {
                        title: "Confirm"
                      }
                    })
                  ) {
                    await deleteLead(uuid);
                    navigate(deleteLeadNavigateTo ?? "/lead", {
                      replace: true
                    });
                  }
                }}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </>
        </Stack>

        <Divider mb={5} mt={2} />

        {!!lead_number && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <NumbersIcon />
            </AboutIcon>
            <Typography>{lead_number}</Typography>
          </Stack>
        )}

        {!!site_address && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <Home />
            </AboutIcon>

            <MuiLink
              href={`https://www.google.com/maps/search/?${qs.stringify({
                api: 1,
                query: site_address
              })}`}
              target="_blank"
            >
              {site_address}
            </MuiLink>
          </Stack>
        )}

        {!!notes && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <FileText />
            </AboutIcon>

            <Typography>{notes}</Typography>
          </Stack>
        )}

        {!!lead_status && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <InfoIcon />
            </AboutIcon>

            <Typography>Status:</Typography>

            <Select
              disabled={isLoadingPatchLead || ability.cannot("UpdateLead", "lead")}
              variant="standard"
              size="small"
              value={lead_status?.uuid}
              onChange={handlePatchLead("lead_status_uuid")}
            >
              {formOptionsData?.lead_status?.map(({ uuid, name }: any) => (
                <MenuItem key={uuid} value={uuid}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}

        {!!lead_source && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <InfoIcon />
            </AboutIcon>

            <Typography>Source: </Typography>

            <Select
              disabled={isLoadingPatchLead || ability.cannot("UpdateLead", "lead")}
              variant="standard"
              size="small"
              value={lead_source?.uuid}
              onChange={handlePatchLead("lead_source_uuid")}
            >
              {formOptionsData?.lead_source?.map(({ uuid, name }: any) => (
                <MenuItem key={uuid} value={uuid}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}

        {!!marketings && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <InfoIcon />
            </AboutIcon>

            <Typography>
              Marketing: {marketings?.map(({ name }: any) => name).join(", ")}
            </Typography>
          </Stack>
        )}

        {!!sales_rep_user && (
          <Stack direction="row" my={2} spacing={2} alignItems="center">
            <AboutIcon>
              <InfoIcon />
            </AboutIcon>

            <Typography>Sales Rep:</Typography>

            <Select
              disabled={isLoadingPatchLead || ability.cannot("UpdateLead", "lead")}
              variant="standard"
              size="small"
              value={sales_rep_user?.uuid}
              onChange={handlePatchLead("sales_rep_user_uuid")}
            >
              {formOptionsData?.sales_rep_user?.map(({ uuid, first_name, last_name }: any) => (
                <MenuItem key={uuid} value={uuid}>
                  {`${first_name} ${last_name}`}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Can I="CreateOrder" a="order">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            fullWidth
            startIcon={<AddIcon />}
            onClick={async () => {
              if (
                await confirm({
                  confirmation: "You are about to create Order. Are you sure?",
                  options: {
                    title: "Confirm"
                  }
                })
              ) {
                const response = await addOrderFromLead({
                  lead_uuid: uuid,
                  customer_uuid: customer?.uuid,
                  sales_rep_uuid: sales_rep_user?.uuid,
                  order_type: "quote",
                  site_address
                });

                setTimeout(() => {
                  navigate(
                    generatePath("/order/:uuid/edit/step-2", {
                      uuid: response.uuid
                    })
                  );
                }, 1000);
              }
            }}
          >
            Create Order from lead
          </Button>
        </Can>
      </CardActions>
    </Card>
  );
}

export default LeadDetailsCard;
