import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as EyeIcon
} from "@mui/icons-material";
import confirm from "../../components/Confirm";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { IconButton, Link, Tooltip } from "@mui/material";
import {
  useMutationBulkDeleteInventoryItem,
  useMutationDeleteInventoryItem,
  useQueryInventoryItems
} from "../../api/InventoryItem";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { withSearchQueryProvider } from "../../contexts/SeachQueryContext";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useHealthChecker from "../../hooks/useHealthChecker";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const InventoryItemList = () => {
  useSetPageTitle("Inventory Items");
  const { mutateAsync: deleteItem } = useMutationDeleteInventoryItem();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();

  const columns: GridColDef[] = [
    { field: "code", headerName: "Code", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "product_line",
      headerName: "Line",
      flex: 1,
      renderCell: params => {
        const {
          value: productLineName,
          row: { product_line }
        } = params;

        if (product_line) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/product-line/:uuid/edit", {
                uuid: product_line?.uuid
              })}
            >
              {productLineName}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.product_line?.name
    },
    {
      field: "product_sold_by",
      headerName: "Sold By",
      flex: 1,
      renderCell: params => {
        const {
          value: productSoldByName,
          row: { product_sold_by }
        } = params;

        if (product_sold_by) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/product-sold-by/:uuid/edit", {
                uuid: product_sold_by?.uuid
              })}
            >
              {productSoldByName}
            </Link>
          );
        }
      },
      // for sorting
      valueGetter: (value, row) => row?.product_sold_by?.name
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { uuid } = row;

        return [
          <Tooltip title="Details" key={`details-${uuid}`}>
            <IconButton
              size="large"
              component={RouterLink}
              to={generatePath("/inventory-item/:uuid/details", { uuid })}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
            >
              <EyeIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Edit" key={`edit-${uuid}`}>
            <IconButton
              size="large"
              component={RouterLink}
              to={generatePath("/inventory-item/:uuid/edit", { uuid })}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete" key={`delete-${uuid}`}>
            <IconButton
              size="large"
              onClick={async () => {
                if (
                  await confirm({
                    confirmation: "You are about to delete. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  })
                ) {
                  await deleteItem(uuid);
                }
              }}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ];
      },
      flex: 1
    }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withSearchQueryProvider(
  withDataGridPageProvider(InventoryItemList, {
    useQueryList: useQueryInventoryItems,
    useMutationBulkDelete: useMutationBulkDeleteInventoryItem,
    addButtonNavigateTo: "/inventory-item/add"
  })
);
