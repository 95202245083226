import React from "react";
import { PusherProvider as BasePusherProvider, PusherProviderProps } from "@harelpls/use-pusher";
import { pusherJSOptions } from "../config.ts";
import useAuth from "../hooks/useAuth.ts";

interface Props {
  children: React.ReactNode;
}

const config: PusherProviderProps = {
  ...pusherJSOptions,
  cluster: "cluster", // arbitrary
  wsPath: "/ws",
  disableStats: true,
  enabledTransports: ["ws", "wss"]
};

function PusherProvider({ children }: Props) {
  const { isAuthenticated } = useAuth();
  return (
    // @ts-expect-error quick
    <BasePusherProvider {...config} defer={!isAuthenticated}>
      {children}
    </BasePusherProvider>
  );
}

export { PusherProvider };
