import { endOfDay, startOfYear } from "date-fns";
import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  useQueryParams,
  withDefault
} from "use-query-params";

export const useFilterQueryParam = () =>
  useQueryParams(
    {
      startDate: withDefault(DateTimeParam, startOfYear(new Date())),
      endDate: withDefault(DateTimeParam, endOfDay(new Date())),
      dateKey: StringParam,
      deleted: withDefault(BooleanParam, false)
    },
    { removeDefaultsFromUrl: true }
  );
