import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams
} from "react-router-dom";
import { Home, MoreVertical } from "react-feather";
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import qs from "qs";
import {
  Add as AddIcon,
  Call as CallIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PhoneAndroid as PhoneAndroidIcon,
  RemoveRedEye as EyeIcon
} from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useQueryOneCustomer } from "../../api/Customer";
import { useQueryGoogleMapByAddress } from "../../api/GoogleMap";
import Loader from "../../components/Loader";
import confirm from "../../components/Confirm";
import { useMutationAddOrder } from "../../api/Order";
import { v4 } from "uuid";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useAuthAbility } from "../../hooks/useAuthAbility";
import { Can } from "../../casl";
import { selectSalesRepFormDialog } from "./SelectSalesRepFormDialog";
import { GoogleMap } from "@react-google-maps/api";
import { useGlobalGoogleMapJsApiLoader } from "../../hooks/useGlobalGoogleMapJsApiLoader";

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const AboutIcon = styled.span`
  display: flex;
  svg {
    width: 14px;
    height: 14px;
  }
`;

function CustomerDetailsCardV2({
  customer_uuid,
  hideDetailsButton,
  hideMoreOptionsButton,
  hideCreateOrderFromButton,
  disableCustomerDeleteBtn,
  site_address
}: {
  customer_uuid?: string;
  hideDetailsButton?: boolean;
  hideMoreOptionsButton?: boolean;
  hideCreateOrderFromButton?: boolean;
  disableCustomerDeleteBtn?: boolean;
  site_address?: string;
}) {
  const { uuid } = useParams();
  const { data: customer, isFetching } = useQueryOneCustomer(customer_uuid ?? uuid, {
    suspense: false
  });

  const navigate = useNavigate();
  const ctx = useOutletContext<any>();
  const { mutateAsync: addOrderFromCustomer } = useMutationAddOrder(v4());

  const { data: geocodeLatLngSiteAddress } = useQueryGoogleMapByAddress(site_address);
  const { data: geocodeLatLngBillingAddress } = useQueryGoogleMapByAddress(
    customer?.billing_address
  );

  const ability = useAuthAbility();

  const location = useLocation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `customer-${customer_uuid ?? uuid}`
  });

  const { isLoaded: isGoogleMapJSAPILoaded, loadError: loadErrorGoogleJSApi } =
    useGlobalGoogleMapJsApiLoader();

  if (loadErrorGoogleJSApi) {
    return <Alert severity="error">Map did not load successfully</Alert>;
  }

  if (isFetching || !isGoogleMapJSAPILoaded) {
    return <Loader />;
  }

  return (
    <>
      {(geocodeLatLngSiteAddress || geocodeLatLngBillingAddress) && (
        <CardMedia sx={{ height: 170 }} title={customer?.full_name}>
          <>
            {isGoogleMapJSAPILoaded && (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%"
                }}
                zoom={11}
                center={geocodeLatLngSiteAddress || geocodeLatLngBillingAddress}
              />
            )}
          </>
        </CardMedia>
      )}
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column">
            {!!customer?.company_name && (
              <Typography gutterBottom variant="h3" component="div">
                {customer?.company_name}
              </Typography>
            )}

            <Typography gutterBottom variant="h3" component="div">
              {customer?.full_name}
            </Typography>
          </Stack>

          {!hideMoreOptionsButton && (
            <IconButton {...bindTrigger(popupState)} size="small" disableFocusRipple>
              <MoreVertical />
            </IconButton>
          )}

          <Menu {...bindMenu(popupState)}>
            {!hideDetailsButton && (
              <MenuItem
                disabled={ability.cannot("Read", "customers")}
                onClick={() => {
                  popupState.close();
                  navigate(
                    ctx?.detailsCustomerBtnUrl ??
                      generatePath(
                        "/customers/:uuid/details?" +
                          qs.stringify({
                            navigate_to: location.pathname + location.search
                          }),
                        { uuid: customer?.uuid }
                      )
                  );
                }}
              >
                <ListItemIcon>
                  <EyeIcon />
                </ListItemIcon>
                Details
              </MenuItem>
            )}

            <MenuItem
              disabled={ability.cannot("UpdateCustomer", "customers")}
              onClick={() => {
                popupState.close();
                navigate(
                  ctx?.editCustomerBtnUrl ??
                    generatePath(
                      "/customers/:uuid/edit?" +
                        qs.stringify({
                          navigate_to: location.pathname + location.search
                        }),
                      { uuid: customer?.uuid }
                    )
                );
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              disabled={disableCustomerDeleteBtn || ability.cannot("DeleteCustomer", "customers")}
              onClick={async () => {
                popupState.close();
                if (
                  await confirm({
                    confirmation: "You are about to delete Customer. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  })
                ) {
                  // await deleteLead(uuid);
                  navigate(ctx?.deleteLeadNavigateTo ?? "/lead", {
                    replace: true
                  });
                }
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
        </Stack>

        {customer?.billing_address && (
          <MuiLink
            underline="hover"
            href={`https://www.google.com/maps/search/?${qs.stringify({
              api: 1,
              query: customer?.billing_address
            })}`}
            target="_blank"
          >
            <Typography gutterBottom variant="h6" component="div">
              {customer?.billing_address}
            </Typography>
          </MuiLink>
        )}

        {site_address && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <Home />
            </AboutIcon>

            <MuiLink
              underline="hover"
              href={`https://www.google.com/maps/search/?${qs.stringify({
                api: 1,
                query: site_address
              })}`}
              target="_blank"
            >
              <Typography>{site_address}</Typography>
            </MuiLink>
          </Stack>
        )}
        {!!customer?.mobile_number && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <PhoneAndroidIcon />
            </AboutIcon>
            <MuiLink href={`tel:${customer.mobile_number}`}>{customer.mobile_number}</MuiLink>
          </Stack>
        )}
        {!!customer?.phone_number && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <CallIcon />
            </AboutIcon>
            <MuiLink href={`tel:${customer.phone_number}`}>{customer.phone_number}</MuiLink>
          </Stack>
        )}
        {!!customer?.email && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <AlternateEmailIcon />
            </AboutIcon>
            <MuiLink href={`mailto:${customer.email}`}>{customer.email}</MuiLink>
          </Stack>
        )}
      </CardContent>
      <CardActions>
        {!!customer?.uuid && !hideDetailsButton && ability.can("Read", "customers") && (
          <Button
            size="small"
            component={Link}
            to={generatePath(
              "/customers/:uuid/details?" +
                qs.stringify({
                  navigate_to: location.pathname + location.search
                }),
              { uuid: customer?.uuid }
            )}
          >
            Detail
          </Button>
        )}

        {!hideCreateOrderFromButton && (
          <Can I="CreateOrder" a="order">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick={async () => {
                const sales_rep_uuid = await selectSalesRepFormDialog({
                  title: "Select Sales Rep"
                });

                if (sales_rep_uuid === false) {
                  return;
                }

                const response = await addOrderFromCustomer({
                  customer_uuid: customer?.uuid,
                  sales_rep_uuid,
                  order_type: "quote"
                });

                setTimeout(() => {
                  navigate(
                    generatePath("/order/:uuid/edit/step-2", {
                      uuid: response.uuid
                    })
                  );
                }, 1500);
              }}
            >
              Create Order from customer
            </Button>
          </Can>
        )}
      </CardActions>
    </>
  );
}

export default CustomerDetailsCardV2;
