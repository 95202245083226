import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import useHealthChecker from "../../../hooks/useHealthChecker.ts";
import React, { memo, useState } from "react";
import { Save as SaveIcon } from "@mui/icons-material";
import { omit } from "lodash";

export const TextFieldInput = memo(
  ({
    isLoading,
    disabled,
    ...props
  }: {
    isLoading?: boolean;
    disabled?: boolean;
    onChange?: (value?: string) => void;
  } & Omit<TextFieldProps, "onChange">) => {
    const { isSuspendMutations } = useHealthChecker();

    const [value, setValue] = useState<string>(props.defaultValue as string);

    const handleSubmit = () => {
      props.onChange && props.onChange(value);
    };

    return (
      <TextField
        disabled={disabled || isSuspendMutations || isLoading}
        helperText={isLoading ? "Saving..." : undefined}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
        autoFocus
        variant="standard"
        value={value ?? ""}
        {...omit(props, "defaultValue")}
        sx={{ minWidth: 100 }}
        onChange={e => setValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSubmit} edge="end" size="small" disabled={isLoading}>
                <SaveIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
);
