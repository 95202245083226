import React, { Dispatch, SetStateAction, useState } from "react";
import { QueryType } from "../types/payload";

type SearchQueryType = {
  query: QueryType;
  setQuery: Dispatch<SetStateAction<QueryType>>;

  resetQuery: () => void;
};

const SearchQueryContext = React.createContext<SearchQueryType | undefined>(undefined);

type SearchQueryProviderProps = {
  children: React.ReactNode;
  defaultState?: QueryType;
};

function SearchQueryProvider({ children, defaultState }: SearchQueryProviderProps) {
  const [query, setQuery] = useState<QueryType>(defaultState ?? {});

  const resetQuery = () => {
    setQuery(defaultState ?? {});
  };

  return (
    <SearchQueryContext.Provider value={{ query, setQuery, resetQuery }}>
      {children}
    </SearchQueryContext.Provider>
  );
}

function withSearchQueryProvider<P>(
  WrappedComponent: React.ComponentType<P>,
  options?: { defaultSearchQuery?: QueryType }
) {
  return (props: any) => {
    return (
      <SearchQueryProvider defaultState={options?.defaultSearchQuery}>
        <WrappedComponent {...props} />
      </SearchQueryProvider>
    );
  };
}

export { SearchQueryProvider, SearchQueryContext, withSearchQueryProvider };
