import { useContext } from "react";
import { SearchQueryContext } from "../contexts/SeachQueryContext";

const useSearchQuery = () => {
  const context = useContext(SearchQueryContext);

  if (!context) throw new Error("SearchQueryContext must be placed within SearchQueryProvider");

  return context;
};

export default useSearchQuery;
