import React, { Suspense } from "react";
import {
  useMutationAddOrderLineItem,
  useQueryOrderLineItemFormOptions
} from "../../../api/OrderLineItem";
import {
  Avatar,
  Card as MuiCard,
  CardContent,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { OrderFormOutletContext } from "./OrderForm";
import { useQueryParam } from "use-query-params";
import { v4 } from "uuid";
import { MutationResultType } from "../../../hooks/useMutationFormAbstract";
import PageLoading from "../../../components/PageLoading";

const Card = styled(MuiCard)(spacing);

export type FormStep2OutletContext = Required<OrderFormOutletContext> & {
  mutationOrderLineItem: MutationResultType;
  product_uuid?: string;
  product?: any;
};

const FormStep2 = () => {
  const context = useOutletContext<Required<OrderFormOutletContext>>();
  const [productUuid, setProductUUID] = useQueryParam("product_uuid");
  const { data: formOptions } = useQueryOrderLineItemFormOptions();
  const mutationAddOrderLineItem = useMutationAddOrderLineItem(v4());

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container columnSpacing={6}>
          <Grid item xs={12} lg={4}>
            <List>
              {formOptions?.products?.map((product: any, index: number) => {
                return (
                  <ListItemButton
                    key={product.uuid}
                    selected={productUuid === product.uuid}
                    onClick={() => setProductUUID(product.uuid)}
                    divider={index < formOptions?.products?.length - 1}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" variant="square" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={product.code}
                      secondary={<React.Fragment>— {product.description}</React.Fragment>}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Suspense fallback={<PageLoading />}>
              <Outlet
                context={{
                  ...context,
                  mutationOrderLineItem: mutationAddOrderLineItem,
                  product_uuid: productUuid,
                  product: formOptions?.products?.find(
                    (product: any) => product.uuid === productUuid
                  )
                }}
              />
            </Suspense>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FormStep2;
