import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { useQueryOneEvent } from "../../api/EvtStore.ts";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { collapseAllNested, defaultStyles, JsonView } from "react-json-view-lite";
import Loader from "../../components/Loader.tsx";

export const EventJSONDialog = () => {
  const [openEventPosition, setOpenEventPosition] = useQueryParam(
    "open_event_position",
    withDefault(NumberParam, undefined),
    { removeDefaultsFromUrl: true }
  );

  const { data: selectedEvent, isFetching } = useQueryOneEvent(openEventPosition);

  const handleClose = () => {
    setOpenEventPosition(undefined);
  };

  return (
    <Dialog open={!!openEventPosition} onClose={handleClose}>
      <DialogTitle>Event</DialogTitle>
      <DialogContent>
        <Box marginTop={3}>
          {isFetching ? (
            <Loader />
          ) : (
            !!selectedEvent && (
              <JsonView
                data={selectedEvent.event}
                shouldExpandNode={collapseAllNested}
                style={defaultStyles}
              />
            )
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
