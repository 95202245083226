import useHealthChecker from "../../../hooks/useHealthChecker.ts";
import { datePickerDialog } from "../../../components/DatePickerDialog.tsx";
import { memo, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";

export const DateInput = memo(
  ({
    title,
    value: defaultValue,
    onChange,
    isLoading,
    disableRemove
  }: {
    title: string;
    value?: Date | null;
    onChange?: (value: Date | null) => void;
    isLoading?: boolean;
    disableRemove?: boolean;
  }) => {
    const [value, setValue] = useState<Date | null | undefined>(defaultValue);
    const { isSuspendMutations } = useHealthChecker();

    return (
      <TextField
        sx={{ minWidth: 140 }}
        disabled={isSuspendMutations || isLoading}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        value={value ? format(value, "dd/MM/yyyy") : ""}
        helperText={isLoading ? "Saving..." : undefined}
        variant="standard"
        InputLabelProps={{
          shrink: !!value
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disabled={isSuspendMutations || isLoading}
                onClick={async () => {
                  const datetime = await datePickerDialog({
                    title,
                    defaultDate: value || undefined
                  });

                  if (datetime) {
                    setValue(datetime);
                    onChange && onChange(datetime);
                  }
                }}
                edge="end"
              >
                <CalendarMonthIcon />
              </IconButton>
              {!disableRemove && !!value && (
                <IconButton
                  size="small"
                  disabled={isSuspendMutations || isLoading}
                  onClick={async () => {
                    setValue(null);
                    onChange && onChange(null);
                  }}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    );
  }
);
