import EventStoreList from "./EventStoreList";

const routes = [
  {
    path: "/event-store",
    element: <EventStoreList />
  }
];

export default routes;
